.menuItems {
  max-height: 270px;
  overflow-y: scroll;
  background-color: #f8fbff;
}
.clientSearch {
  margin-bottom: 30px;
}
.clientSearch .css-dxnb72-MuiFormControl-root {
  width: 379px;
}
.clientItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clientItem .MuiBox-root {
  background: #ffffff;
  width: 377px;
  margin-left: 40px;
  border: 2px solid #e7eff9;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #003087;
  padding: 22px 30px;
}
.clientItem button {
  padding: 0 !important;
  background: transparent !important;
  min-width: auto !important;
  margin-left: 15px !important;
}
.clientItem button:hover {
  opacity: 0.7 !important;
}
.gym.MuiBox-root {
  width: 380px !important;
  margin: 0 0 0 auto !important;
}
.MuiStepper-root {
  max-width: 590px;
  margin: 0 auto;
}
.MuiTabs-flexContainer {
  border-bottom: 1px solid #d9d9d9;
  background: #f8fbff;
}
.dynamicTabs .MuiTabs-flexContainer .MuiTab-root {
  width: 20% !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  height: 48px;
  color: #000000;
}
.dynamicTabs .MuiTabs-root,
.dynamicTabs .MuiTabs-flexContainer,
.dynamicTabs .MuiTabs-indicator {
  height: 48px;
  
}

.dynamicTabs .MuiTabs-indicator {
  box-shadow: none;
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #003087;
}
/*custome stepper*/
.stepper-change .MuiStepLabel-iconContainer {
  position: relative;
  width: 35px;
  height: 35px;
}

.stepper-change .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  color: transparent !important;
  border: 2px solid #003087 !important;
  border-radius: 20px !important;
  width: 35px !important;
  height: 35px !important;
}

.stepper-change .Mui-disabled .MuiSvgIcon-root {
  border-color: #0a49bcd1 !important;
  border-width: 1px !important;
}

.stepper-change .MuiStepConnector-line {
  border-color: #91b0e8f2 !important;
}

.stepper-change .Mui-active .MuiStepConnector-line,
.stepper-change .Mui-completed .MuiStepConnector-line {
  border-color: #003087 !important;
}

.stepper-change .MuiStepConnector-horizontal {
  top: 17px !important;
  left: calc(-50% + 17px) !important;
  right: calc(50% + 17px) !important;
}

.stepper-change .MuiStepLabel-iconContainer::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #003087;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.MuiBox-root .MuiGrid-root .MuiStepper-root .Mui-completed .MuiStepLabel-horizontal .MuiStepLabel-iconContainer::before {
  height:0px;
  }

.stepper-change .Mui-disabled::before,
.stepper-change .Mui-completed::before {
  content: unset !important;
}

.stepper-change .Mui-completed .MuiSvgIcon-root {
  fill: #003087 !important;
  border: unset !important;
}

.stepper-change .Mui-active,
.stepper-change .Mui-completed {
  /* color: var(--text-primary) !important; */
  font-weight: bold !important;
  color: #003087 !important;
}

/*end*/
.dynamicTabs .MuiTabs-flexContainer .MuiTab-root.Mui-selected {
  color: #003087 !important;
}
div[role='tabpanel'] > .MuiBox-root {
  padding: 0;
}
/* .cartTable>div{padding: 0 !important;} */
.cartTable:not(.no-width) .MuiDataGrid-cell:nth-of-type(1),
.cartTable:not(.no-width) .MuiDataGrid-columnHeader:nth-of-type(1) {
  width: 12% !important;
  min-width: 12% !important;
}
.cartTable:not(.no-width) .MuiDataGrid-cell,
.cartTable:not(.no-width) .MuiDataGrid-columnHeader {
  width: 18% !important;
  min-width: 18% !important;
}
.cartFilter .cartTable .MuiPaper-root {
  border: 1px solid #d9d9d9;
  border-radius: 19px 19px 0px 0px;
}
.cartFilter.cartTable .MuiPaper-root {
  border: 0;
}
.cartTable .css-v8c0h1-MuiDataGrid-root .MuiDataGrid-cellContent {
  overflow: visible;
}
.filterBtn,
.searchBar .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(5, 50, 136, 0.5) !important;
  border-radius: 10px;
}
.searchBar .MuiInputLabel-shrink {
  background: #ffff !important;
  height: 25px;
  padding: 0 10px;
  line-height: 30px;
}
.orderSuccess {
  padding: 20vh 30px;
}

.checkoutModal .css-1pu3y48-MuiPaper-root-MuiDialog-paper {
  max-width: 575px;
  width: 575px;
  padding: 0 35px;
}

.checkoutModal .MuiDialogContent-root {
  padding: 20px 15px 35px;
}
.checkoutModal .dialogTitle {
  padding: 20px 15px 0;
  line-height: 1;
}
.checkoutModal .MuiButtonBase-root {
  padding: 14px 0 0px;
}
.checkoutModal.transactionModal .dialogTitle {
  padding-top: 45px;
  font-weight: 600;
  font-size: 40px;
  color: #003087;
  line-height: 1;
}
.checkoutModal.transactionModal .dialogTitle .MuiTypography-root {
  font-weight: 600;
  font-size: 40px;
  color: #003087;
}
.transactionModal .MuiDialogContent-root {
  padding: 0;
}
.transactionModal .MuiDialogContent-root p .MuiTypography-root {
  font-weight: 400;
  font-size: 20px;
  color: #003087;
  margin-bottom: 0 !important;
}
.transactionModal .MuiDialogContent-root p {
  line-height: 1;
  margin-bottom: 30px;
}
.custom-table .MuiDataGrid-row .MuiDataGrid-cell:last-child {
  display: none !important;
  width: 0px !important;
}
.css-v8c0h1-MuiDataGrid-root .MuiDataGrid-row,
.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  display: flex;
  width: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.custom-table .MuiDataGrid-row .MuiDataGrid-cell {
  border-bottom: 0px;
}
.custom-table .MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent {
  text-wrap: wrap;
}
.custom-table .MuiDataGrid-row {
  border-bottom: 1px solid;
  border-color: rgba(224, 224, 224, 1);
  justify-content: space-between;
}
.termsFlex {
  display: block!important;
  align-items: flex-start;
  padding-top: 25px;
  margin-bottom: 70px;
  padding: 18px;
}
.termsFlex > .MuiBox-root {
  width: 100%!important;
}
.termsFlex .MuiTypography-body1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;

  color: #003087;
  margin-bottom: 15px;
}
.checkoutTermsBox {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  max-width: 100%;
  padding: 17px 35px 17px 17px;
  border: 1px solid #eaeaea;
  max-height: 136px;
  overflow-y: auto;
  border-radius: 15px!important;
  margin-bottom: 30px;
}
.termsFlex .right {
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-self: stretch;
}
.termsFlex .right > .MuiBox-root:nth-of-type(1) {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.termsFlex .right .MuiCheckbox-root {
  padding: 0;
}
.termsFlex .right > .MuiBox-root:nth-of-type(1) .MuiTypography-body1 {
  margin-left: 15px;
  margin-bottom: 0;
  color: #000000;
}
.termsFlex .right > .MuiBox-root:nth-of-type(2) {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-left: 40px;
}
.topStripe {
  position: absolute;
  top: -60px;
  left: -5px;
  width: 100%;
  z-index: 11;
}
.servDropDownSec .MuiSelect-select {
  min-height: 1.1222px;
  padding: 10px;
}
.css-13rhbfq-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  background: #fff !important;
}
.Mui-disabled {
  color: #000 ;
  font-weight: bolder !important;
 }
@media (max-width: 1120px) {
  .paymentHead {
    max-width: 40%;
  }
}
@media (max-width: 991px) {
  .paymentHead {
    max-width: 47%;
  }
  .termsFlex > .MuiBox-root {
    width: 47%;
  }
}
/* new css  */
.memBox {
  width: 380px;
  display: flex;
  justify-content: flex-start;
}
.memBox .MuiBox-root {
  background: #ffffff;
  width: 377px;
  margin-left: 40px;
  border: 2px solid #d13a00;
  border-radius: 5px;
  font-weight: 800 !important;
  font-size: 15px !important;
  color: #c2dcfc;
  padding: 22px 30px;
}
.successBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}
.successBox .MuiBox-root {
  background: #ffffff;
  width: 377px;
  margin-left: 40px;
  border: 2px solid #32b2ac;
  border-radius: 5px;
  font-weight: 800 !important;
  font-size: 15px !important;
  color: #003087;
  padding: 22px 30px;
}
.clientItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clientItem .MuiBox-root {
  background: #ffffff;
  width: 377px;
  margin-left: 40px;
  border: 2px solid #e7eff9;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #003087;
  padding: 22px 30px;
}

/* .clientItem .css-cpknzq-MuiButtonBase-root-MuiButton-root */
.clientItem .remove-client-button {
  padding: 0;
  background: transparent;
  min-width: auto;
  margin-left: 15px;
}

.clientItem .remove-client-button:hover {
  /* opacity: 0.7; */
  background-color: transparent !important;
}
.status-field.MuiAutocomplete-hasPopupIcon.css-dkhvny-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 15px !important;
}
.status-field .css-i4bv87-MuiSvgIcon-root {
  color: #00308780 !important;
}
.css-1ez0rq7-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  color: #7a91bd !important;
}
.css-13rhbfq-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  color: #7a91bd !important;
}

.css-1ez0rq7-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: #7a91bd !important;
}

.giftCardWrap .success .MuiFormHelperText-root{
  color: green
}

.giftCardWrap .failed .MuiFormHelperText-root{
  color: #D13A00;
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(0,48,135);
  background: linear-gradient(0deg, rgba(0,48,135) 33%, rgb(194, 194, 248) 100%);
  animation: spin 0.8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Circle with Check Icon */

.circle-box {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid green; /* Green border */
  border-radius: 50%;
  background: fff; /* Green color */
  position: relative;
}

.check-icon {
  content: "\2713"; /* Unicode checkmark symbol */
  font-size: 36px; /* Adjust the size of the checkmark icon */
  color: white; /* Icon color */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
