/* .App {
  text-align: center;
}
 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
 
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .css-ov5d0v {
    display: inline-block !important;
}
.css-udqyoc {
  display: inline-block !important;
} */
/* .css-udqyoc, .css-p9u8ul, .css-lqdrr5 {
  background: #003087 !important;
  box-shadow: 1px 0 0 #003087 !important;
} */

@import '../node_modules/@syncfusion/ej2-react-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';

.css-1d5gtz8-MuiAvatar-root {
  margin-right: 15px;
}

.profile-container {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
}

@media only screen and (max-width: 850px) {
  .content-layout-main {
    overflow: scroll;
    margin-right: 56px;
  }
}

.profile-container .profile-img {
  margin-right: 15px;
}

.list-on-hover {
  z-index: 0;
  margin-top: -10px !important;
}

/* .onHover-styling {
  display: block !important;
} */

.link-style {
  text-decoration: none !important;
}

.link-style:visited {
  color: inherit !important;
}

.css-1sxlfji .MuiList-root .MuiListItem-root {
  padding-bottom: 0px !important;
}

.menu-list .list-item-link .list-on-hover {
  border-bottom-left-radius: 5% !important;
  border-bottom-right-radius: 5% !important;
}

.list-item-link .list-on-hover {
  background-color: #f6e6dd !important;
  display: block;
  padding: 2px 0px 0px 10px !important;
  list-style-type: none;
  cursor: pointer;
}

.list-item-link .list-on-hover li {
  display: block;
}

/* .menu-list .onHover-styling.active {
  background-color: #FFF0E8 !important;
  color: #003087 !important;
} */

.list-item-link {
  display: block !important;
  z-index: 1;
}

.list-item-link:hover span {
  color: #003087;
}

.list-item-link:hover .icon-style {
  color: #003087 !important;
  float: right !important;
}

.menu-list .list-item-link {
  border-radius: 5% !important;
}

.list-item-link:hover .MuiButton-startIcon {
  background-color: #e35205 !important;
  color: #ffffff !important;
}

.list-text-style {
  padding: 10px;
  font-weight: 600 !important;
}

.menu-list .list-item-link .list-on-hover .list-text-style {
  font-size: 15px;
  color: #003087 !important;
}

.menu-list .list-item-link .list-on-hover .list-text-style:hover,
.menu-list .list-item-link .list-on-hover .list-text-style.active {
  color: #e35205 !important;
}

.menu-list .list-item-link .list-btn-link.active,
.menu-list .list-item-link .list-btn-link:hover {
  background-color: #fff0e8 !important;
  color: #003087 !important;
  opacity: 1 !important;
}

.menu-list .list-item-link .list-btn-link {
  padding: 6px 10px !important;
  color: #ffffff !important;
  font-size: 15px;
}

.menu-list .list-item-link .list-btn-link .MuiButton-startIcon {
  margin-right: 9px;
  /* background-color: #E35205;*/
  color: #ffffff !important;
  border-radius: 50%;
  padding: 9px;
  border: 2px solid #ffffff;
}

/* .css-1lk56yk .MuiList-root .MuiListItem-root .MuiButton-root .MuiButton-startIcon */
.menu-list .list-item-link .list-btn-link.active .MuiButton-startIcon,
.menu-list .list-item-link .list-btn-link:hover .MuiButton-startIcon,
.menu-list .list-item-link .list-btn-link.active .MuiButton-endIcon,
.menu-list .list-item-link .list-btn-link:hover .MuiButton-endIcon {
  background-color: #e35205 !important;
  color: #ffffff !important;
  border: 2px solid #e35205;
}

.menu-list .list-item-link {
  padding: 5px 0 !important;
  display: block !important;
  z-index: 1;
}

.menu-list .list-on-hover {
  z-index: 0;
  margin-top: -10px !important;
  padding-top: 10px !important;
}

.hamburger-cont {
  /* position: absolute; */
  bottom: -18px;
  left: -21px;
  z-index: 10;
  -webkit-transition: all 0.4s ease;
}

.sidebar-wrapper {
  z-index: 5 !important;
  background: #003087 !important;
  box-shadow: 1px 0 0 #003087 !important;
  left: -1px !important;
  -webkit-transition: all 0.4s ease;
}

.absolute {
  position: absolute;
}

.hamburger-cont .hamburger-icon-btn {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: #fff0e8;
  box-shadow: 1px 0px 6px -1px rgba(0, 0, 0, 0.13);
}

.hamburger-cont .hamburger-icon-btn:hover {
  background-color: #fff0e8;
}

.hamburger-cont .hamburger-icon-btn svg {
  fill: #e35205;
}

/* .add-btn-container .btn-primary {
  background-color:#003087
}
.add-btn-container .btn-primary:hover {
  background-color:#003087
} */

/* .main-container {
  margin-top: 25px;
} */
.main-container .add-btn-container {
  /* visibility: hidden; */
  /* position: absolute;
  top: -58px;
  right: 27px;
  z-index: 10; */
  margin-bottom: 25px;
}

.relative {
  position: relative;
}

.card-header .MuiCardHeader-content,
.card-header .MuiCardHeader-content .MuiCardHeader-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.card-header .MuiCardHeader-title {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 30px !important;
  align-items: center;
  color: #003087;
}

.card-header .MuiCardHeader-title .MuiCardHeader-subheader,
.add-promotion-title {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 16px;
  align-items: center;
  color: #000000;
}

.card-grid-container .card-avatar-img {
  background-color: #003087;
  border: 2px solid #e35205;
}

.fieldset.MuiOutlinedInput-notchedOutline,
.Search-field fieldset.MuiOutlinedInput-notchedOutline {
  border: 2px solid #003087 !important;
  outline: none;
  letter-spacing: inherit;
}

.status-field-lbl,
.Search-field label {
  color: #003087 !important;
}

.franchise-table-container .edit-btn,
.location-table-container .edit-btn,
.client-table-container .edit-btn,
.user-table-container .edit-btn,
.staff-table-container .edit-btn,
.manage-supplier-table-container .edit-btn,
.table-container .edit-btn {
  background: #f8fbff;
  border: 0.4px solid #c5c5c5;
  border-radius: 6px;
  color: #525252;
  font-size: 10px;
  padding: 6px;
  visibility: hidden;
}

.franchise-table-container .table-row:hover .edit-btn,
.table-container .table-row:hover .edit-btn,
.location-table-container .table-row:hover .edit-btn,
.client-table-container .table-row:hover .edit-btn,
.user-table-container .table-row:hover .edit-btn,
.staff-table-container .table-row:hover .edit-btn,
.table-container .edit-btn:hover {
  visibility: initial;
}

.franchise-table-container .edit-btn:hover,
.table-container .edit-btn:hover,
.location-table-container .edit-btn:hover,
.client-table-container .edit-btn:hover,
.user-table-container .edit-btn:hover,
.staff-table-container .edit-btn:hover,
.table-container .edit-btn {
  background: #f8fbff;
}

/* .sidebar-wrapper {
  display: inline-block !important;
} */
/* .header-wrapper {
  left: 290px !important;
  width: auto !important;
} */

.franchise-table-container .table-header th,
.table-container .table-header th,
.location-table-container .table-header th,
.user-table-container .table-header th,
.client-table-container .table-header th,
.staff-table-container .table-header th,
.inventary-table-container .table-header th,
.expiry-stock-container .table-header th,
.common-table-container .common-header th {
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  text-transform: capitalize !important;
}

.franchise-table-container,
.location-table-container,
.table-container,
.client-table-container,
.user-table-container,
.staff-table-container,
.inventary-table-container,
.expiry-stock-container,
.common-table-container {
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
}

.franchise-table-container .table-row .table-td div,
.table-container .table-row .table-td div,
.table-container .table-row .table-td,
.location-table-container .table-row .table-td div,
.client-table-container .table-row .table-td div,
.user-table-container .table-row .table-td div,
.staff-table-container .table-row .table-td div,
.inventary-table-container .table-row .table-td div,
.expiry-stock-container .table-row .table-td,
tbody td {
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.franchise-table-container .table-header th,
.table-container .table-header th,
.location-table-container .table-header th,
.client-table-container .table-header th,
.user-table-container .table-header th,
.staff-table-container .table-header th,
thead th {
  background-color: #f8fbff !important;
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

/* .franchise-table-container .table-row, .location-table-container .table-row {
  border-top: 1px solid rgba(81, 81, 81, 1) !important;
  border-top-color: rgba(0, 0, 0, 0.1);
} */
/* .franchise-table-container .table-row:hover, .location-table-container .table-row:hover {
  border-bottom-color: #003087 !important;
  border-top-color: #003087 !important;
} */
/* .franchise-table-container .table-row:hover .table-td, .location-table-container .table-row:hover .table-td {
  border-bottom-color: #003087 !important;
  border-top: 1px solid #003087 !important;
} */
.franchise-table-container .table-row .table-td,
.table-container .table-row .table-td,
.location-table-container .table-row .table-td,
.user-table-container .table-row .table-td,
.client-table-container .table-row .table-td,
.staff-table-container .table-row .table-td {
  padding: 8px 16px !important;
}

.franchise-table-container .MuiTableRow-hover:hover,
.table-container .MuiTableRow-hover:hover,
.location-table-container .MuiTableRow-hover:hover,
.user-table-container .table-row .table-td,
.client-table-container .table-row .table-td,
.staff-table-container .table-row .table-td {
  background-color: #f8fbff !important;
}

.page-header-text {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 40px !important;
  letter-spacing: -0.022em;
  color: #003087;
}

.table-header-name .MuiTypography-h5.MuiCardHeader-title {
  font-size: 20px !important;
}

.logout-btn-cont .logout-btn {
  margin-top: 8px !important;
  cursor: pointer;
}

.logout-btn-cont .logout-btn {
  width: 0.9rem;
}

.card-table-main {
  border-radius: 9px 19px 0px 0px !important;
}

.drodown_menu {
  font-style: unset;
  color: #000;
  font-weight: 600;
}

.status-field>[class*='MuiSvgIcon-root-MuiSelect-icon'] {
  color: #000000;
}

.Search-field [class*='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium'] {
  color: #7c95c3 !important;
}

.card-header .MuiCardHeader-subheader {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #000000 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.card-content .MuiCardContent-root .MuiTypography-body2 {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #000000 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-content {
  max-height: min-content !important;
}

.filter-field-lbl {
  font-weight: 700 !important;
  color: #003087 !important;
}

.filter-field-cont {
  width: 150px;
}

/* For mobile : like samsung grand(480 * 800): */
@media screen and (max-width: 480px) {
  .page-header-text {
    font-size: 20px !important;
  }
}

/* For iphone: */
@media screen and (max-width: 320px) {
  .page-header-text {
    font-size: 20px !important;
  }
}

/* For ipad: */
@media screen and (max-width: 768px) {
  .page-header-text {
    font-size: 30px !important;
  }

  .card-header .MuiCardHeader-subheader {
    font-size: 15px !important;
  }
}

/* For ipad: */
@media screen and (max-width: 1920px) {
  .dialog-box.dialog-box-right .MuiDialog-paperScrollPaper[aria-describedby='details-dialog'] {
    /* width: calc(100vh - 50px) !important; */
    width: calc(100vw - 300px) !important;
  }
}

/* For ipad:AIR potrate*/
@media screen and (max-width: 800px) {
  .dialog-box.dialog-box-right .MuiDialog-paperScrollPaper[aria-describedby='details-dialog'] {
    /* width: calc(100vh - 350px) !important; */
    width: calc(100vw - 100px) !important;
  }
}

/* For ipad:AIR */
@media (min-width: 800px) and (max-width: 850px) {
  .dialog-box.dialog-box-right .MuiDialog-paperScrollPaper[aria-describedby='details-dialog'] {
    /* width: calc(100vh - 450px) !important; */
    width: calc(100vw - 150px) !important;
  }
}

/* @media screen and (min-width : 320px){
  .page-header-text {
    font-size: 20px !important;
  }
} */

.list-item-link .list-btn-link>span .MuiSvgIcon-root {
  font-size: 1.8rem !important;
}

.no-data {
  font-weight: 600;
  font-size: 17px;
}

.location-details-wrapper .location-name {
  margin-right: 20px;
  float: left;
}

.page-header {
  font-weight: 600;
  font-size: 30px !important;
  letter-spacing: -0.022em;
  color: #003087;
  display: flex;
}

.location-details-wrapper .location-details-container .location-details-row .location-details-col .location-details-label,
.user-details-wrapper .user-details-container .details-row .details-col .details-label {
  float: left;
  font-weight: 700;
  color: #525252;
  margin-right: 20px;
  text-transform: uppercase;
  /* line-height: 17px; */
}

.location-details-wrapper .location-details-container .location-details-row .location-details-col .location-details-value,
.user-details-wrapper .user-details-container .details-row .details-col .details-value {
  font-weight: 700;
  font-size: 16px !important;
  /* line-height: 17px; */
  color: #a5a5a5;
}

.location-details-wrapper .location-details-container .location-details-row .location-details-col {
  line-height: 25px;
}

.location-details-wrapper .location-header-container .details-remove-icon {
  /* float: left;
  margin-top: 8px; */
  cursor: pointer;
  margin-right: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.no-hover:hover {
  background-color: unset !important;
}

.location-details-wrapper .location-image {
  margin-top: 3px;
}

.text-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}

.alert-title {
  font-weight: 600 !important;
  font-size: 17px !important;
  color: #000000 !important;
}

.alert-decription {
  /* font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.078em;
  line-height: 18px; */
  color: #000000 !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left;
}

.alert-button-control {
  justify-content: space-evenly !important;
  padding: 0px 8px !important;
}

.alert-button-control .vertical-divider {
  height: 50px !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.cancel-btn {
  color: #e35205 !important;
}

.text-error {
  color: #e35205 !important;
}

.text-black {
  color: #000000 !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-35 {
  font-size: 35px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.weight-600 {
  font-weight: 600 !important;
}

.weight-400 {
  font-weight: 400 !important;
}

.weight-700 {
  font-weight: 700 !important;
}

.error-message {
  color: #d13a00 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.dialog-header {
  background: #7891c036;
}

.p-0 {
  padding: 0px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-36 {
  padding-top: 36.5px !important;
}

.bg-white .MuiInputBase-multiline {
  background: white !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
  font-family: 'Myriad Pro', sans-serif !important;
}

.mt-20 {
  margin-top: 20px !important;
  /* overflow: inherit !important; */
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml {
  margin-left: 5px !important;
}

/* .MuiPaper-elevation{
  overflow: inherit !important;
} */

.react-quill-height {
  height: 400px;
  /* border-radius: 5% !important;
  border:2px solid #003088 !important;
  border-bottom: 0px !important; */
}

.react-quill-height .ql-toolbar.ql-snow {
  border: 2px solid #003088 !important;
  border-bottom: 0px !important;
}

.react-quill-height .ql-toolbar.ql-snow+.ql-container.ql-snow {
  border: 2px solid #003088 !important;
}

.react-quill-height .ql-snow .ql-stroke {
  stroke: #003088 !important;
}

.react-quill-height .ql-snow .ql-fill {
  fill: #003088 !important;
}

.react-quill-height .ql-snow .ql-picker {
  color: #003088 !important;
}

.ql-editor p {
  color: black !important;
}

.dialog-box.dialog-box-right:not(.not) {
  left: unset;
}

.float-left {
  float: left;
}

.dialog-box.dialog-box-right:not(.not) .MuiDialog-paperScrollPaper[aria-describedby='details-dialog'] {
  margin: 0px !important;
}

.dialog-box.dialog-box-right .MuiDialog-paperScrollPaper[aria-describedby='details-dialog'] {
  max-height: 100vh;
  /* width: calc(100vh - 350px); */
  width: calc(100vw - 300px);
}

.dialog-box.dialog-box-right.franchisee .MuiDialog-paperScrollPaper[aria-describedby='details-dialog'] {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-light-blue {
  background-color: #f8fbff !important;
}

.dialog-box.dialog-box-right .MuiDialog-container[role='presentation'] {
  align-items: inherit;
}

.loader-container .loader-img {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input:-webkit-autofill {
  /* -webkit-box-shadow: 0 0 0 100px #F8FBFF inset !important; */
  -webkit-box-shadow: 0 0 0 100px white inset !important;
  -webkit-text-fill-color: #000 !important;
  caret-color: #fff;
  border-radius: inherit;
}

.loader-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #000000;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container .loader-msg {
  color: #fff;
  margin: 45px 4px;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 
.product-details-img {
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  height: 220px;
}

.product-details-img .product-img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.product-details-img .product-img {
  height: 220px;
  padding: 20px;
  width: 100%;
} */

/* .product-details-img .no-media {
  background: rgba(0, 48, 135, 0.53);
} */

.product-details-img {
  /* background: rgba(0, 48, 135, 0.53); */
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  height: 220px;
  width: 100%;
  padding: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .product-details-img .product-img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

.product-details-img .product-img {
  /*   height: 220px;
  padding: 20px;
  width: 320px; */
  max-width: 100%;
  max-height: 100%;
}

.slick-prev {
  left: 5px !important;
  top: 110px !important;
  /* background: #003087 !important; */
  z-index: 1 !important;
  width: 25px !important;
  height: 25px !important;
}

.slick-next {
  /* background: #003087 !important; */
  z-index: 1 !important;
  right: 5px !important;
  top: 110px !important;
  width: 25px !important;
  height: 25px !important;
}

.card-image-carousel {
  margin-top: 20px;
}

.single-uploaded-images {
  width: 25%;
  height: 60px;
  border: 1px solid #d9d9d9;
  float: left;
  /* display: flex;
  vertical-align: middle;
  justify-content: center; */
  margin: 0 10px 10px 0px;
  position: relative;
  padding: 8px;
  display: flex;
  justify-content: center;
}

.single-uploaded-images img {
  max-width: 100%;
  max-height: 100%;
  /* margin-top: 10px; */
}

.remove-image {
  position: absolute;
  top: -5px;
  right: 2px;
  font-size: 15px;
  cursor: pointer;
}

.header-wrapper {
  -webkit-transition: all 0.4s ease;
}

.header-wrapper:hover {
  z-index: 6;
}

.product-right-cont {
  padding-left: 25px;
}

.filter-content {
  background-color: #f8fbff !important;
  border-radius: 19px !important;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0 12px rgba(0, 0, 0, 0.1) !important;
  padding: 20px !important;
}

.filter-content .filter-label {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  color: #003087 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.custom-form .MuiOutlinedInput-notchedOutline {
  border: 2px solid #003088 !important;
  border-radius: 12px;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid#003088 !important;
  border-radius: 12px;
}

.profile-img svg,
.user-profile-img svg {
  font-size: 2.5rem;
}

.required-field .required-label:after {
  content: ' *';
  color: #053288;
}

.required-field>[class*='Mui-focused'] fieldset legend,
.required-field .required-label.MuiFormLabel-filled+div fieldset legend {
  padding: 4px;
}

/* .input-field-styling > [class *="MuiInputBase-root-MuiOutlinedInput-root"]
  .input-field-styling > [class *="Mui-focused"], .input-field-styling .label-style-create-franchisee.Mui-focused */

.clock-container .clock-icon {
  margin-bottom: -6px;
}

.task-name {
  font-size: 16px;
  font-weight: 700;
}

.text-blue,
.text-primary {
  color: #003087 !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.w-50 {
  width: 50% !important;
}

.staff-task-height {
  height: 556px !important;
}

.card-radius {
  border-radius: 19px 19px 0px 0px;
}

.add-img-cont {
  border: 1px dashed #003087;
  width: 25%;
  margin-top: 20px;
}

.add-img-cont-staff {
  border: 1px dashed #003087;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.radius-5 {
  border-radius: 5px;
}

.MuiFormLabel-root {
  color: #053288 !important;
}

.primary-color {
  color: #053288;
}

.cameraImgContainer {
  position: relative;
  height: 220px;
  /* width: 320px; */
  background: #000;
  margin: auto;
  border-radius: 20px;
}

.cameraImgContainer img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  top: 50%;
  /* margin: 0px; */
  padding: 10px;
  /* height: 220px; */
  /* width: 320px; */
  transform: translate(-50%, -50%);
}

.cameraButton {
  background-color: #053288 !important;
  width: 100%;
  color: #fff;
  margin: 5px 0 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #053288 !important;
}

.MuiCircularProgress-indeterminate {
  animation: circular-rotate 1.4s linear infinite;
}

@keyframes circular-rotate {
  0% {
    transform: rotate(0deg);
    /* Fix IE11 wobbly */
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(360deg);
  }
}

.p-info {
  margin-left: 30px !important;
}

.field-error-text {
  color: #d13a00 !important;
  margin-left: 10px !important;
}

.only-navigation-icon {
  display: flex !important;
  justify-content: center !important;
  border-radius: 0px !important;
  /* background-color: #fff !important; */
}

.sidebar-half .menu-list .list-item-link .list-btn-link.only-navigation-icon.active,
.sidebar-half .menu-list .list-item-link .list-btn-link.only-navigation-icon:hover {
  background-color: #fff !important;
}

.list-link-container {
  padding: 9px 0px !important;
}

.sidebar-wrapper.sidebar-half {
  width: 104px !important;
  min-width: 104px !important;
}

.hamburger-cont.collapese-arrow {
  position: absolute;
  top: 61px;
}

.hamburger-cont.collapese-arrow.arrow-half {
  left: 66px;
}

.hamburger-cont.collapese-arrow.arrow-full {
  left: 253px;
}

.collapese-arrow .collapese-arrow-btn {
  border-radius: 50%;
  height: 37px;
  width: 37px;
  background: #fff0e8;
  box-shadow: 1px 0px 6px -1px rgba(0, 0, 0, 0.13);
}

.content-layout-wrapper.content-layout-half {
  margin-left: 104px !important;
}

.header-wrapper.header-wrapper-half {
  left: 104px !important;
}

/* bundleinpusfields */
.bundleInput [class*='MuiInputBase-formControl'] {
  height: 70px !important;
}

/* Add another pet start*/
.input-field-styling [class*='MuiInputBase-formControl'] {
  height: 70px !important;
  background-color: #ffffff !important;
}

/* .select-drop-down-label-styling>[class *="MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root"] {
  height: 70px !important;
} */

.select-drop-down-label-styling .input-field-styling {
  height: 70px !important;
}

.title-text {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  /* color: #003087 !important; */
}

.heading-text {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  color: #003087 !important;
}

/* .check-box-text,
.radio-button-text [class *="MuiTypography-root"],
.radio-button-heading {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  width: 60% !important;
  max-width: 100% !important;
} */

.radio-button-heading {
  font-weight: 600 !important;
  font-size: 17px !important;
  color: #003087 !important;
}

.check-box-text,
.radio-button-text [class*='MuiTypography-root'] {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.text-area-style {
  margin-top: 15px !important;
  width: 80% !important;
  max-width: 100% !important;
  display: block;
}

.label-style-create-franchisee,
.input-field-dropdown-styling,
.input-field-dropdown-styling-top-6,
.custom-form .MuiFormControl-root .MuiFormLabel-root {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #003087 !important;
  letter-spacing: -0.05em !important;
  /* top: 8px !important; */
}

.input-field-dropdown-styling {
  top: 8px !important;
}

.input-field-dropdown-styling-top-6 {
  top: 6px !important;
}

.label-style-create-franchisee[class*='Mui-focused'],
.label-style-create-franchisee[class*='Mui-focused'],
.label-style-create-franchisee[class*='Mui-focused'],
.label-style-create-franchisee[class*='MuiFormLabel-filled'],
.label-style-create-franchisee[class*='MuiFormLabel-filled'],
.label-style-create-franchisee[class*='MuiFormLabel-filled'],
.input-field-dropdown-styling[class*='Mui-focused'],
.input-field-dropdown-styling[class*='MuiFormLabel-filled'],
.input-field-dropdown-styling-top-6[class*='Mui-focused'],
.input-field-dropdown-styling-top-6[class*='MuiFormLabel-filled'],
.label-style-create-franchisee[class*='MuiFormLabel-filled'],
.label-style-create-franchisee[class*='Mui-focused'],
.edit-address-label[class*='Mui-focused'],
.edit-address-label[class*='MuiFormLabel-filled'] {
  top: 0px !important;
  letter-spacing: -0.05em !important;
}

.MuiSelect-select {
  color: #000000 !important;
}

.notifyDropdown .MuiSelect-select {
  color: rgba(5, 50, 136, 1) !important;
  font-weight: bold;
}

.notifyDropdown.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(0, 48, 135, 0.5) !important;
}

/* .menuItemTax, .MuiSelect-select {
   color: rgba(5, 50, 136, 1) !important;
  font-weight: bold;
} */
.label-style-create-franchisee.Mui-focused,
.custom-form .MuiFormControl-root .MuiFormLabel-root.Mui-focused {
  top: 0px !important;
}

.label-style-create-franchisee.MuiInputLabel-shrink,
.custom-form .MuiFormControl-root .MuiInputLabel-shrink {
  top: 0px !important;
}

.select-drop-down-label-styling>[class*='Mui-focused'] {
  top: 0px !important;
}

.MuiSvgIcon-root-MuiSelect-icon {
  color: #003087 !important;
}

.radio-button-style [class*='MuiSvgIcon-root'],
.radio-button-style span.MuiFormControlLabel-label {
  color: #003087 !important;
}

.select-drop-down-label-styling .MuiSelect-select#demo-simple-select-helper,
.text-area-styl #my-textarea {
  font-size: 16px !important;
}

/* Css class for step bar text styling when on active page*/
.step-labeling>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  color: #003087 !important;
}

/* Css class for step bar text styling when on remaining page*/

.step-labeling>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label,
.step-labeling span.Mui-disabled {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000000 !important;
}

/* Css class for step bar text styling when page is completed*/

.step-labeling>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-completed,
.step-labeling,
.step-labeling span.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  letter-spacing: -0.022em;
  color: #003087 !important;
}

/* Css class for step bar when it is not active */
.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root {
  color: transparent !important;
}

.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root.Mui-active {
  color: #003087 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  height: 0.8rem !important;
  width: 0.8rem !important;
}

/* Css class for display text of step no as none*/

.MuiStepIcon-text {
  display: none !important;
}

/* Css for step bar when step is completed*/

.step-labeling>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.Mui-completed {
  color: #003087 !important;
  /* margin: auto !important; */
  margin: -3px !important;
  vertical-align: middle !important;
  height: auto !important;
  border-radius: 100% !important;
  width: 1.5em !important;
  /*border: solid #003087 !important ;
  border-spacing:0px !important;*/
}

.step-labeling>.MuiStepLabel-iconContainer {
  border-radius: 50% !important;
  border: 2px solid #003087 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;
}

/* Css styling for horizontal line */

.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #003087 !important;
}

.custom-header-text {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  color: #000000 !important;
  font-size: 20px;
  font-weight: 700;
  top: 5px !important;
}

.m-auto-12 {
  margin: 12px 0 !important;
}

.m-20 {
  margin: 20px 0 !important;
}

/*Add another pet end*/

.common-form-container .form-field-label,
.common-form-container .dropdown-field .MuiInputLabel-formControl {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #053288 !important;
  letter-spacing: -0.05em !important;
  top: 8px !important;
}

/* .common-form-container .MuiInputBase-formControl, */
.common-form-container .MuiAutocomplete-inputRoot,
.common-form-container .dropdown-field {
  height: 70px !important;
  background: #ffffff !important;
}

.common-form-container .css-1m2g103-MuiFormLabel-root-MuiInputLabel-root.Mui-error,
.css-7j8z08-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #d13a00 !important;
}

.common-form-container .form-field-label[class*='Mui-focused'],
.common-form-container .form-field-label[class*='Mui-focused'],
.common-form-container .form-field-label[class*='Mui-focused'],
.common-form-container .form-field-label[class*='MuiFormLabel-filled'],
.common-form-container .form-field-label[class*='MuiFormLabel-filled'],
.common-form-container .form-field-label[class*='MuiFormLabel-filled'],
.common-form-container .input-field-dropdown-styling[class*='Mui-focused'],
.common-form-container .input-field-dropdown-styling[class*='MuiFormLabel-filled'],
.common-form-container .form-field-label[class*='MuiFormLabel-filled'],
.common-form-container .form-field-label[class*='Mui-focused'],
.common-form-container .edit-address-label[class*='Mui-focused'],
.common-form-container .edit-address-label[class*='MuiFormLabel-filled'],
.common-form-container .dropdown-field .MuiInputLabel-root[class*='MuiFormLabel-filled'],
.common-form-container .dropdown-field .MuiInputLabel-root[class*='Mui-focused'] {
  top: 0px !important;
}

.common-form-container .css-1ez0rq7-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline,
.common-form-container .css-1grb88j-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d13a00 !important;
}

.common-form-container input[type='text'],
textarea,
.common-form-container .MuiInputBase-root {
  background: #ffffff !important;
}

.common-form-container .MuiFormHelperText-root {
  color: #d13a00 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.primary-color {
  color: #053288 !important;
}

.user-table-container .user-icon {
  width: 40px;
  height: 40px;
  background: #d9d9d9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #a8a8a8;
  margin-right: 15px;
  float: left;
}

.user-list-container .column-icon {
  float: left;
  margin-top: 15px;
  margin-right: 15px;
}

.fixed {
  position: fixed;
}

.sidebar-right {
  background: #f8fbff;
  right: 0;
  height: 100%;
  top: 0px;
  z-index: 10;
  width: 56px;
  padding: 20px 0;
}

.content-layout-main {
  margin-right: 56px;
}

.alert-notification-cont .alert-notification-icon {
  font-size: 2rem;
}

.alert-notification-cont .icons-bottom-cont {
  bottom: 30px;
  right: 4px;
}

/* Calendar CSS by VV */
.-min-field-height div.MuiOutlinedInput-root {
  min-height: 70px !important;
}

.-team-container-white-box {
  background-color: #fff;
}

.-logo-none {
  display: none;
}

.-logo-block {
  display: block;
}

.Email-field input[type='text'] {
  background: transparent !important;
}

.logo-image {
  max-width: 100%;
}

.logo-image-small {
  width: 50px;
}

hr {
  margin-left: none !important;
}

.logo-image,
.zoom-room-side-logo {
  -webkit-transition: all 0.4s ease;
}

.subMenu-Icon {
  right: 10px;
  position: absolute;
}

.location-filters {
  justify-content: flex-start;
  gap: 16px;
}

.time-filters {
  justify-content: flex-start;
  gap: 4px;
}

@media (min-width: 600px) {
  .time-filters {
    justify-content: flex-end;
    gap: 12px;
  }
}

@media (max-width: 599px) {
  .time-filters {
    margin-top: 4px !important;
  }

  .location-filters {
    margin-top: 0 !important;
  }
}

.filter-grid {
  margin-top: 60px;
  background: #f8fbff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 50px;
  position: relative;
  padding: 12px 0;
}

.filter-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.arrow-prev {
  left: 10px;
}

.arrow-next {
  right: 10px;
}

.month-picker .selected-month {
  font-family: 'Myriad Pro Semibold', Arial, sans-serif;
  display: flex;
  gap: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #3b3b3b;
  cursor: pointer;
}

.month-picker .selected-month svg {
  width: 24px;
  height: 24px;
}

.month-picker .year-btn {
  position: absolute;
  top: 5px;
  right: -80px;
  font-size: 13px;
  height: unset;
  padding: 0;
  border: unset;
  text-transform: capitalize;
}

.month-picker .year-btn:hover,
.month-picker .year-btn:focus {
  background: transparent;
  outline: none;
  box-shadow: none;
}

.css-10qfyyb-MuiStack-root> :not(style)+ :not(style) {
  margin-top: 0px !important;
}

.css-m6hxwi-MuiStack-root> :not(style)+ :not(style) {
  margin: 13px !important;
}

.css-16qu4ly-MuiTypography-root {
  color: #fff !important;
}

/* .css-1oyxw9v-MuiContainer-root{
  padding: 0px;
} */

.month-picker .month-popup {
  position: absolute;
  z-index: 99;
  max-width: 230px;
  max-height: 150px;
  margin-top: 10px;
  background: #fff;
  padding: 12px;
  padding-top: 8px;
}

@media (max-width: 399px) {
  .month-picker-year {
    margin-left: -60px;
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  .month-picker-year {
    margin-left: -30px;
  }
}

.month-cards-grid {
  width: 100% !important;
  margin-left: 0px !important;
  margin-top: 30px !important;
  margin-right: -16px !important;

  max-height: 415px;
  overflow-y: auto;
}

/* @media (min-width: 600px) {
  .month-cards-grid > :nth-child(3n + 1) {
    padding-left: 0px !important;
  }
} */
/* .media (min-width: 1280px)
<style>
.css-1oyxw9v-MuiContainer-root {
    max-width: 0px;

} */
/* .css-xpc7ku-MuiSvgIcon-root {
  height: 20px !important;
} */
.month-cards-grid::-webkit-scrollbar-track {
  background-color: #efefef;
}

.month-cards-grid::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 7px;
  border: 2px solid transparent;
  background-clip: content-box;
}

/* .css-1ml7tgh-MuiStack-root>:not(style)+:not(style){
  margin-left: 0px !important;
} */
.custom-image {
  height: auto !important;
  width: 20px !important;
  color: #003087 !important;
}

.custom-image-rotate {
  height: auto !important;
  width: 20px !important;
  color: #003087 !important;
  transform: rotate(180deg) !important;
}

.month-card {
  background: #f8fbff;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  padding: 20px;
  position: relative;

  /* max-height: 125px; */
}

.card-action {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 15px !important;
}

.month-card .date {
  font-family: 'Myriad Pro Bold', Arial, sans-serif;
  height: 75px;
  width: 60px;
  background: var(--text-primary);
  text-align: center;
  color: #fff;
  padding: 4px;
  border-radius: 5px 0px 0px 5px;
  background-color: #003087;
  /* color: #FFFFFF; */
}

/* .month-card .content {
  max-height: 75px;
} */

.month-card .content p {
  font-family: 'Myriad Pro Regular', Arial, sans-serif;
  color: #000;
  letter-spacing: 0.04em;
  font-size: 12px;
  font-weight: 600;
}

.month-card .content .hours {
  font-family: 'Myriad Pro Bold', Arial, sans-serif;
  font-weight: 700;
  color: #000;
}

.month-card .content .hour {
  color: #003087 !important;
  /* color: ; */
}

.time-picker {
  font-family: 'Myriad Pro Regular', Arial, sans-serif;
  color: #000;
  /* letter-spacing: 0.04em; */
  font-size: 12px;
  font-weight: 600;
}

.time-picker,
.time-picker:focus-visible {
  border: unset;
  background: transparent;
  outline: none;
  box-shadow: none;
}

.week-card-grid {
  border: 2px solid rgba(0, 48, 135, 0.5);
  margin-top: 16px;
}

.week-card {
  min-height: 250px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d3d2d2;
  /* border-top: 2px solid #d3d2d2; */
}

.week-contain-wrapper {
  background-color: #7891c036;
}

.week-card .day-name {
  padding: 12px 0;
  text-align: center;
  font-family: 'Myriad Pro Bold', Arial, sans-serif;
  font-weight: 700;
  color: rgba(0, 48, 135, 0.8);
  letter-spacing: 0.04em;
  border-bottom: 2px solid #d3d2d2;
  /* color: #FFFFFF; */
}

.week-card .week-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 30px 15px;
  margin-top: 20px;
  padding-bottom: 20px;
  font-family: 'Myriad Pro Regular', Arial, sans-serif;
  color: #000;
  letter-spacing: 0.04em;
  font-size: 13px;
  font-weight: 600;
}

.week-card .active {
  padding: 40px 15px;
}

.week-card .week-content .content-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 4px;
}

.week-card .week-content .content-inner:first-child {
  padding-bottom: 4px;
  border-bottom: 1px solid #d3d2d2;
}

.week-card .week-content .content-inner:last-child {
  padding-top: 4px;
}

.week-card .week-total {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 6px;
  font-family: 'Myriad Pro Regular', Arial, sans-serif;
  color: #000;
  letter-spacing: 0.04em;
  font-size: 13px;
  font-weight: 600;
}

.dialog-box.dialog-box-right.inventry-alert-dialog .MuiDialog-paperScrollPaper[aria-describedby='details-dialog'] {
  width: 400px !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.card-header-style {
  padding: 16px 0px !important;
}

.overflow-auto {
  overflow: auto !important;
}

.input-value-increment {
  background: #fcfcfc;
  border: 1px solid #d0d0d0;
  border-radius: 45px;
  /* margin: 0 10px; */
  padding: 3px 5px;
  width: 60px;
  text-align: center;
}

.control-btn {
  justify-content: space-evenly;
  flex-direction: column !important;
}

.spaceBetween {
  justify-content: space-between;
}

.content-end {
  justify-content: end;
}

.alert-notification-cont .alert-notification-icon {
  font-size: 2rem;
}

.alert-notification-cont .icons-bottom-cont {
  bottom: 30px;
  right: 4px;
}

.alert-label {
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  /* margin-bottom: 10px; */
  display: flex;
}

.inventory-alerts {
  border: 0.4px solid #c5c5c5;
  margin-bottom: 10px;
}

.unread-border {
  border: 2px solid #003087;
}

.read-border {
  border: 0.4px solid #c5c5c5;
}

.inventory-alerts .alert-content {
  padding-bottom: 16px !important;
}

.alert-label .volumn-icon {
  margin-left: 20px;
}

.main-alert-container .alert-arrow-navigate {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clock-content .clock-in-button,
.staff-details .clock-in-button {
  background: #f8fbff;
  border: 0.4px solid #003087 !important;
  border-radius: 12px !important;
  color: #525252 !important;
  min-width: 130px !important;
}

.clock-content .clock-button {
  width: 250px !important;
  margin-right: 0px !important;
}

.clock-content .clock-datepicker fieldset {
  border: none !important;
}

.clock-content .clock-datepicker input {
  font-size: 28px !important;
  font-weight: 600 !important;
  color: #000 !important;
  width: 163px !important;
  height: 30px !important;
  padding: 0px;
}

.clock-content .clock-in-location {
  position: absolute;

  bottom: 20px;

  right: 40%;
}

.staff-table-container .table-row .table-td .clock-time {
  color: #003087 !important;

  font-weight: 600 !important;

  float: left;

  margin-right: 10px;
}

.clock-time-edit .input-field-styling [class*='MuiInputBase-formControl'] {
  height: 50px !important;
  width: 250px !important;
}

.clock-time-edit .input-field-styling [class*='MuiInputBase-formControl'] input {
  color: #003087 !important;
  font-weight: 600 !important;
  font-size: 16px;
  text-align: center;
}

.staff-table-container .three-dots-icon {
  margin-left: 15px;
}

.clock-time {
  font-size: 10px;
}

.select-drop-down-label-styling .input-field-styling {
  background-color: #ffffff !important;
  height: 70px !important;
}

.logout-btn-cont {
  right: 25px;
}

.v-middle {
  vertical-align: middle;
}

.control-btn {
  justify-content: space-evenly;
  flex-direction: column !important;
}

.spaceBetween {
  justify-content: space-between;
}

.content-end {
  justify-content: end;
}

/* Inventory CSS */

.inventory-container .container {
  font-family: 'Myriad Pro Regular';
  margin-top: 1rem;
  width: 100%;
}

.inventory-container .summary-actions {
  width: 100%;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #003087 !important;
}

.text-area-label {
  color: #053288 !important;
  font-family: 'Myriad Pro Semibold' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 0.7px !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 48, 135, 0.5) !important;
  border-width: 2px !important;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  font-weight: 600;
}

.MuiFormControl-root label {
  color: #7c95c3ed;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-bottom: lightgray;
  box-shadow: none;
}

.orange-icons {
  /* color: darkorange; */
  color: #e35205 !important;
  font-size: 1.3rem;
  font-weight: 600;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
  background-color: #f2f6fa;
  box-shadow: none;
}

/* .accordian-header {
  font-size: 1rem;
  font-weight: 600;
} */

.css-1ygcj2i-MuiTableCell-root {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

textarea {
  font-family: 'Myriad Pro Regular';
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 0px 12px 0 12px;
  background: #fff;
  border: 2px solid rgba(0, 48, 135, 0.5);
}

textarea:focus-visible {
  outline: none !important;
}

.loader {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 6rem !important;
}

.mui-dataGrid {
  border-width: 0px !important;
}

.mui-dataGrid .MuiDataGrid-columnHeaderTitle {
  color: #000;
  font-weight: 600;
}

.css-1kcfknn-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1kcfknn-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-1kcfknn-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-1kcfknn-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-overlayWrapper {
  min-height: 150px !important;
}

.summary-close-btn {
  margin-right: 12px;
  margin-top: 6px;
}

.c-container .css-1x3az0q-MuiDataGrid-root .MuiDataGrid-row:hover,
.c-container .css-1x3az0q-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background-color: transparent !important;
}

.c-container .sort-dropdown .MuiInputBase-input {
  padding: 20px 6px !important;
}

.c-container .MuiDataGrid-columnHeaders,
.c-container .MuiDataGrid-withBorderColor {
  border-color: rgba(224, 224, 224, 1) !important;
}

.c-container .css-1x3az0q-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.c-container .css-1x3az0q-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.c-container .MuiDataGrid-cellContent {
  color: rgba(0, 0, 0, 0.87) !important;
}

.c-container .purchase-filter .MuiAutocomplete-endAdornment .MuiButtonBase-root {
  border-radius: 5px !important;
}

.c-container .purchase-filter .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator svg {
  width: 12px !important;
  height: 12px !important;
}

.c-container .purchase-filter .MuiInputBase-adornedEnd {
  padding: 9px 39px 20px 9px !important;
}

.purchase-container .MuiDataGrid-main {
  padding-left: 12px !important;
}

.order-container .order-multiline-grid .MuiTextField-root {
  width: 100% !important;
}

.order-container .order-multiline-grid .MuiInputBase-input {
  color: #000000 !important;
}

.order-container .order-details .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}

.order-container .order-details .MuiInputBase-input {
  min-height: 55px !important;
  color: #000000 !important;
}

.order-container .order-details .purchase-filter {
  width: 100% !important;
  margin-top: 0 !important;
}

.order-container .order-details .purchase-filter .MuiAutocomplete-root {
  width: 100% !important;
}

.order-container .order-details .purchase-filter .MuiAutocomplete-root .MuiOutlinedInput-input {
  min-height: 25px !important;
}

.purchase-main .MuiDataGrid-cell {
  color: #000 !important;
}

.no-border {
  border: none !important;
}

/* Inventory CSS */

.inventory-container .container {
  font-family: 'Myriad Pro Regular';
  margin-top: 1rem;
  width: 100%;
}

.inventory-container .summary-actions {
  width: 100%;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #003087 !important;
}

.text-area-label {
  color: #053288 !important;
  font-family: 'Myriad Pro Semibold' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 0.7px !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 48, 135, 0.5) !important;
  border-width: 2px !important;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  font-weight: 600;
}

.MuiFormControl-root label {
  color: #7c95c3ed;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-bottom: lightgray;
  box-shadow: none;
}

.orange-icons {
  color: #e35205;
  font-size: 1.3rem;
  font-weight: bolder !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
  background-color: #f2f6fa;
  box-shadow: none;
}

/* .accordian-header {
  font-size: 1rem;
  font-weight: 600;
} */

.css-1ygcj2i-MuiTableCell-root {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

textarea {
  font-family: 'Myriad Pro Regular';
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 0px 12px 0 12px;
  background: #fff;
  border: 2px solid rgba(0, 48, 135, 0.5);
}

textarea:focus-visible {
  outline: none !important;
}

.fontweight600 {
  font-weight: 600 !important;
}

.loader {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 6rem !important;
}

.css-1kcfknn-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1kcfknn-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-1kcfknn-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-1kcfknn-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-overlayWrapper {
  min-height: 150px !important;
}

.summary-close-btn {
  margin-right: 12px;
  margin-top: 6px;
}

.c-container .css-1x3az0q-MuiDataGrid-root .MuiDataGrid-row:hover,
.c-container .css-1x3az0q-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background-color: transparent !important;
}

.c-container .sort-dropdown .MuiInputBase-input {
  padding: 20px 6px !important;
}

.c-container .MuiDataGrid-columnHeaders,
.c-container .MuiDataGrid-withBorderColor {
  border-color: rgba(224, 224, 224, 1) !important;
}

.c-container .css-1x3az0q-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.c-container .css-1x3az0q-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.c-container .MuiDataGrid-cellContent {
  color: rgba(0, 0, 0, 0.87) !important;
}

.c-container .purchase-filter .MuiAutocomplete-endAdornment .MuiButtonBase-root {
  border-radius: 5px !important;
}

.c-container .purchase-filter .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator svg {
  width: 12px !important;
  height: 12px !important;
}

.c-container .purchase-filter .MuiInputBase-adornedEnd {
  padding: 9px 39px 20px 9px !important;
}

.purchase-container .MuiDataGrid-main {
  padding-left: 12px !important;
}

.order-container .order-multiline-grid .MuiTextField-root {
  width: 100% !important;
}

.order-container .order-multiline-grid .MuiInputBase-input {
  color: #000000 !important;
}

.order-container .order-details .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}

.order-container .order-details .MuiInputBase-input {
  min-height: 55px !important;
  color: #000000 !important;
}

.order-container .order-details .purchase-filter {
  width: 100% !important;
  margin-top: 0 !important;
}

.order-container .order-details .purchase-filter .MuiAutocomplete-root {
  width: 100% !important;
}

.order-container .order-details .purchase-filter .MuiAutocomplete-root .MuiOutlinedInput-input {
  min-height: 25px !important;
}

.purchase-main .MuiDataGrid-cell {
  color: #000 !important;
}

/* ============Services css============== */

/* .custom-container .contained-btn:hover {
  background-color: #00266c !important;
} */

.custom-container .outlined-btn:hover {
  background-color: #0030871a !important;
}

.custom-container .table-header .MuiCardHeader-title {
  font-size: 20px !important;
  color: #003087 !important;
  /* color: var(--text-primary) !important; */
  font-family: 'Myriad Pro Regular', Arial, sans-serif !important;
}

.custom-container .capitalize {
  text-transform: capitalize !important;
}

.custom-container .lowercase {
  text-transform: lowercase !important;
}

.custom-container .uppercase {
  text-transform: uppercase !important;
}

.custom-table .MuiDataGrid-row .edit-btn {
  background: #f8fbff;
  border: 0.4px solid #c5c5c5;
  border-radius: 6px;
  color: #525252;
  font-size: 10px;
  padding: 6px;
  visibility: hidden;
}

.custom-table .MuiDataGrid-row:hover .edit-btn {
  visibility: initial;
}

.custom-table .MuiDataGrid-row .edit-btn:hover {
  background: #f8fbff;
}

.custom-table {
  border-width: 0px !important;
}

.custom-table .MuiDataGrid-columnHeaderTitle {
  color: #000;
  font-weight: 600;
}

.custom-table .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(0, 48, 135, 0.7) !important;
  border-radius: 12px;
}

.custom-table .MuiInputBase-input {
  color: #000 !important;
}

.custom-table .MuiDataGrid-withBorderColor {
  border-color: rgba(224, 224, 224, 1) !important;
}

.custom-table .MuiDataGrid-row {
  cursor: pointer;
}

.custom-table .MuiDataGrid-row:hover,
.custom-table .MuiDataGrid-row.Mui-hovered {
  background-color: transparent !important;
}

.custom-table .MuiDataGrid-cell,
.custom-table .MuiTablePagination-displayedRows {
  color: #000000 !important;
}

.custom-table .MuiDataGrid-columnHeader,
.custom-table .MuiDataGrid-row .MuiDataGrid-cell {
  padding: 0 16px !important;
}

.custom-table .MuiDataGrid-columnHeader:focus-within,
.custom-table .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.custom-table .MuiTablePagination-selectLabel {
  color: black;
  font-weight: 600;
  font-size: 13px;
}

.custom-table .cell-with-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.custom-table .cell-with-image .MuiAvatar-circular {
  width: 30px;
  height: 30px;
}

.custom-table .MuiDataGrid-columnHeadersInner,
.custom-table .MuiDataGrid-columnHeadersInner div[role='row'],
.custom-table .MuiDataGrid-virtualScrollerRenderZone,
.custom-table .MuiDataGrid-row {
  width: 100% !important;
}

.custom-table .MuiDataGrid-columnHeader,
.custom-table .MuiDataGrid-cell {
  max-width: none !important;
}

/* .editable .MuiDataGrid-columnHeader:last-child,
.editable .MuiDataGrid-row .MuiDataGrid-cell:last-child {
  max-width: 0px !important;
} */

.custom-table .MuiDataGrid-cell .MuiDataGrid-actionsCell {
  display: none;
}

.custom-table .MuiDataGrid-cell .MuiDataGrid-actionsCell button {
  padding: 8px 16px !important;
}

.custom-table .MuiDataGrid-row:hover .MuiDataGrid-cell .MuiDataGrid-actionsCell {
  display: block;
}

.custom-table .MuiDataGrid-cell .MuiDataGrid-actionsCell {
  border: 1px solid rgb(218 218 218);
  border-radius: 8px;
}

/* .custom-table .MuiDataGrid-row .MuiDataGrid-cell:last-child {
  display: none !important;
  width: 0px !important;
  padding:  0 !important;
  margin: 0 !important;
} */

/* set width according to table columns */
.custom-container .col-2 .MuiDataGrid-columnHeader,
.custom-container .col-2 .MuiDataGrid-cell {
  width: 48% !important;
}

.custom-container .col-3 .MuiDataGrid-columnHeader,
.custom-container .col-3 .MuiDataGrid-cell {
  width: 31% !important;
}

.custom-container .col-4 .MuiDataGrid-columnHeader,
.custom-container .col-4 .MuiDataGrid-cell {
  width: 23% !important;
}

.col-5 .MuiDataGrid-columnHeader,
.col-5 .MuiDataGrid-cell {
  width: 18% !important;
}

.custom-container .col-6 .MuiDataGrid-columnHeader,
.custom-container .col-6 .MuiDataGrid-cell {
  width: 15% !important;
}

.MuiTabs-flexContainer .MuiTab-root {
  width: 50% !important;
  max-width: none !important;
  color: #000 !important;
  background: #7891c036 !important;
  margin-right: 0px !important;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.css-1rt8z5s-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
  font-weight: 500 !important;
}

.MuiTabs-flexContainer .MuiTab-root:hover {
  color: #000;
}

.css-1rt8z5s-MuiButtonBase-root-MuiTab-root.Mui-selected:hover {
  color: #fff !important;
}

.custom-form .MuiFormControl-root .MuiOutlinedInput-root {
  min-height: 70px !important;
  background-color: #ffffff !important;
}

.custom-form .search-input .MuiFormControl-root .MuiOutlinedInput-root {
  min-height: unset !important;
  background-color: rgb(248, 251, 255) !important;
}

.custom-form .MuiInputBase-input {
  color: #000 !important;
}

.custom-stepper .MuiStepLabel-label {
  font-weight: 600 !important;
  color: #003087 !important;
}

.custom-stepper .Mui-disabled {
  /* color: #000 !important;	
  font-weight: 500 !important;*/
}

/*.custom-stepper .Mui-active {*/
.custom-stepper .MuiSvgIcon-root {
  /* color: var(--text-primary) !important;	 */
}

.custom-switch .MuiFormControlLabel-label {
  font-size: 16px !important;
  color: #000000;
  font-weight: 600 !important;
}

.custom-switch-start .MuiFormControlLabel-label {
  margin-right: 16px !important;
}

.custom-switch-end .MuiFormControlLabel-label {
  margin-left: 16px !important;
}

.custom-switch-top .MuiFormControlLabel-label {
  margin-bottom: 16px !important;
}

.custom-switch-bottom .MuiFormControlLabel-label {
  margin-top: 16px !important;
}

.toggle-fullWidth {
  display: flex !important;
  width: 100% !important;
}

.toggle-spaceBetween {
  justify-content: space-between !important;
}

.toggle-spaceEvenly {
  justify-content: space-evenly !important;
}

.toggle-spaceAround {
  justify-content: space-around !important;
}

/* .custom-form .MuiFormHelperText-root {
  color: #000 !important;
} */

.custom-form .helper-error .MuiFormHelperText-root {
  color: #d13a00 !important;
}

.custom-radio-group {
  width: 100%;
  display: flex;
  text-align: left;
}

.custom-radio-group .MuiFormGroup-root .MuiFormControlLabel-root {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.custom-radio-group .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root {
  color: #000;
  letter-spacing: 0.3px;
  font-weight: 500;
}

.fullwidth-btn button {
  width: 100% !important;
}

.custom-radio-group .MuiSvgIcon-root {
  font-size: 30px;
}

.custom-summary .summary-heading {
  font-family: Myriad Pro !important;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: #474646 !important;
}

.custom-summary .summary-value {
  font-family: Myriad Pro !important;
  font-weight: 600 !important;
  letter-spacing: 0.4px;
}

.custom-summary .edit-button {
  font-family: Myriad Pro !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.service-container .secondary-btn {
  color: #000 !important;
}

.custom-tabs .MuiTabs-flexContainer.css-1pyy021-MuiTabs-flexContainer {
  background: #f8fbff;
}

.custom-tabs .MuiTabs-flexContainer .MuiTab-root {
  border-radius: 0 !important;
  border-bottom: 2px solid rgba(224, 224, 224, 1) !important;
  border-radius: 0 !important;
}

.custom-tabs .MuiTabs-flexContainer .Mui-selected {
  background: transparent !important;
  border-bottom: 3px solid #003087 !important;

  color: #003087 !important;
}

.custom-tabs .MuiTabs-flexContainer .MuiTab-root {
  background: none !important;
  line-height: 40px;
  font-weight: 600 !important;
  font-size: 16px;
}

.custom-tabs .css-1ol5aw2-MuiTabs-indicator {
  background: none !important;
  border: none;
  box-shadow: none;
}

.custom-tabs {
  margin: 26px 0px;
}

.custom-tabs .MuiTabs-scroller.MuiTabs-fixed.css-kqhgxw-MuiTabs-scroller {
  height: 50px;
}

.button-style-manage-supplier {
  background-color: #003087 !important;
  text-transform: none !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-style: normal !important;
  font-weight: 600 !important;
  margin-top: 10px !important;
  height: 50px !important;
}

.custom-filter-fields .MuiFormControl-root .MuiFormLabel-root {
  top: 0px !important;
  font-weight: normal !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  /* color : #000 ! important; */
}

.custom-filter-fields .MuiTypography-root {
  color: #000 !important;
}

.custom-filter-fields .MuiTypography-root.filter-text-title {
  color: #003087 !important;
}

.detail-title {
  color: #003087;
  font-weight: 600;
}

.create-order-heading {
  font-size: 24px;
}

.create-order-default-address {
  height: 32px;
  margin-bottom: 20px;
  font-size: 16px;
  margin-right: 0;
}

.create-order-default-address,
.customs-switch {
  margin-right: -25px;
}

.align-justify {
  text-align: justify;
}

.pr-10 {
  padding-right: 10px;
}

.word-break {
  word-break: break-word;
}

.task-container .task-items,
.notes-container .task-items {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 450px !important;
}

.table-header-name .MuiCardHeader-action {
  align-self: auto !important;
  position: absolute;
  left: 50%;
}

.align-flex-start {
  align-self: flex-start !important;
}

.justify-content-flex-start {
  justify-content: flex-start !important;
}

.table-header-title {
  justify-content: flex-start;
  gap: 12px;
}

.gap-12 {
  gap: 12px;
}

.align-self-center {
  align-self: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

/* ================some general css ================= */
.table-header .th-center {
  text-align: center;
}

.table-row .td-center {
  display: flex;
  justify-content: center;
  text-align: center;
}

.purchase-summary-label {
  min-width: 190px;
}

.summary-page-button button {
  min-width: 220px !important;
  max-width: 250px !important;
}

.fullWidth-btn button {
  min-width: 100% !important;
}

.underline-btn button {
  text-decoration: underline;
  letter-spacing: 0.5px;
}

.no-hover-btn:hover {
  background-color: transparent !important;
}

.no-radius {
  border-radius: 0px;
}

.back-arrow-style.css-i4bv87-MuiSvgIcon-root {
  margin-top: 1px;
  color: #003087 !important;
}

.common-button {
  height: 42px !important;
  min-width: 75px !important;
}

.common-button:hover {
  background-color: #003087 !important;
}

.common-button-staff {
  height: 42px !important;
  min-width: 75px !important;
}

.common-button-staff:hover {
  background-color: #003087 !important;
  color: white !important;
}

.font-myriad {
  font-family: 'Myriad Pro', sans-serif;
}

.display-inline {
  display: inline-block !important;
}

/* ====================Membership css================= */

.membership-table-container .table-header th {
  color: #003087 !important;
}

.toggle-label-black .custom-switch .MuiFormControlLabel-label {
  color: #000 !important;
}

.toggle-label-14 .custom-switch .MuiFormControlLabel-label {
  font-size: 14px !important;
}

.membership-status-container .MuiFormControl-fullWidth {
  width: 225px;
}

.membership-status-container .MuiFormControl-root .MuiOutlinedInput-root {
  font-size: 14px !important;
}

.membership-status-container .MuiFormControl-root .input-field-dropdown-styling {
  top: -4px !important;
  letter-spacing: 0.05px !important;
}

.benefit-section {
  border: 2px solid#003088 !important;
  border-radius: 12px;
  position: relative;
  width: 100%;
  min-height: 250px;
  padding: 14px 24px;
}

.benefit-section .add-btn {
  position: absolute;
  bottom: 16px;
  left: 24px;
}

.benefit-section .no-benefit {
  bottom: unset;
  top: 100px;
}

.benefit-section .more-benefits {
  margin-bottom: 50px;
}

.benefit-section .benefit-heading {
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 16px;
}

.benefit-section .benefit-list {
  padding: 12px 0;
  display: flex;
}

.membership-label-color {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

/* ================Membership - Enroll Mmeber ================= */

.enroll-table-header {
  border: 1px solid #d9d9d9;
  border-bottom: unset;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 16px 50px !important;
}

.enroll-table-container {
  border: 1px solid #d9d9d9;
  border-top: unset;
  border-bottom: unset;
  /* padding: 0 12px !important; */
}

.enroll-table-container tr th:first-child,
.enroll-table-container tr td:first-child {
  padding-left: 35px !important;
}

.enroll-table-container tr th:last-child,
.enroll-table-container tr td:last-child {
  padding-right: 35px !important;
}

.enroll-search .MuiFormControl-root {
  width: 350px !important;
}

.enroll-search .MuiFormControl-fullWidth {
  width: 270px !important;
}

@media screen and (max-width: 1023px) {
  .enroll-search .MuiFormControl-root {
    width: 250px !important;
  }

  .enroll-table-container tr th:first-child,
  .enroll-table-container tr td:first-child {
    padding-left: 12px !important;
  }

  .enroll-table-container tr th:last-child,
  .enroll-table-container tr td:last-child {
    padding-right: 12px !important;
  }

  .enroll-table-header {
    padding: 16px 24px !important;
  }
}

.enroll-search .MuiFormControl-root .MuiOutlinedInput-root {
  min-height: 46px !important;
  font-size: 14px !important;
}

.enroll-search .MuiFormControl-root .input-field-dropdown-styling {
  top: 0 !important;
  letter-spacing: 0.1px !important;
}

.enroll-filters-container .MuiGrid-item:last-child {
  max-width: 75% !important;
}

/* ================some general css ================= */

.table-header .th-center {
  text-align: center;
}

.table-row .td-center {
  display: flex;
  justify-content: center;
  text-align: center;
}

.purchase-summary-label {
  min-width: 190px;
}

.fullWidth-btn button {
  min-width: 100% !important;
}

.underline-btn button {
  text-decoration: underline;
  letter-spacing: 0.5px;
}

.no-hover-btn:hover {
  background-color: transparent !important;
}

.header-blue-16 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #003087 !important;
}

.header-blue-40 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  color: #003087 !important;
}

.header-black-20 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #000000 !important;
  letter-spacing: 1px !important;
}

.header-blue-22 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  color: #003087 !important;
}

.header-blue-20 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #003087 !important;
}

.header-blue-24 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #003087 !important;
}

.header-blue-32 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #003087 !important;
}

.header-blue-40 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  color: #003087 !important;
}

/* CSS CLASSES FOR FOR LIABLITY WIVER */
.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.back-arrow-close {
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.custom-back-arrow-close {
  margin-top: -3px;
  margin-right: 15px;
  cursor: pointer;
}

.MuiTablePagination-toolbar p,
.MuiTablePagination-toolbar div {
  font-weight: bold;
  color: #000;
  font-size: 13px;
}

.black-text-14 {
  color: #000 !important;
  font-size: 14px !important;
}

.membership-status-container .input-value[data-shrink='false'] {
  top: -3px;
}

.membership-status-container .input-value[data-shrink='true'] {
  top: 0;
}

/* ================  Common Style Start by VV ================= */
.holidayName {
  color: #fff !important;
  text-align: center !important;
  font-size: 15px !important;
  margin-top: 5px !important;
  width: 100% !important;
  text-wrap: balance !important;
}

.staffTimeOffDatePick input {
  visibility: hidden;
}

.staffTimeOffDatePick {
  margin-top: -15px !important;
  position: relative;
  cursor: pointer;
}

.staffTimeOffDatePick fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.staffLabelDate {
  position: absolute;
  top: 15px;
  width: 100%;
}

tr,
div {
  user-select: none;
}

.txt-Transform {
  text-transform: capitalize;
}

.Email-field,
.Pass-field,
.Cnfpass-field {
  background-color: #fff !important;
}

.-paginate-right {
  display: 'flex';
  justify-content: 'space-between';
  padding-left: '19px';
  float: 'right';
}

input {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.css-e6fmak-MuiButtonBase-root-MuiPickersDay-root {
  color: #000 !important;
}

.Mui-selected {
  color: #fff !important;
}

.form-datepicker-container .custom-datepicker [class*='MuiInputBase-formControl'] {
  height: 70px !important;
  background-color: #ffffff !important;
}

.form-datepicker-container .MuiInputLabel-formControl {
  top: 8px !important;
}

.form-datepicker-container .MuiFormLabel-root {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #053288 !important;
  letter-spacing: -0.05em !important;
}

.form-datepicker-container .MuiFormLabel-root.Mui-focused {
  top: 0px !important;
}

.form-datepicker-container .MuiFormLabel-root.MuiInputLabel-shrink {
  top: 0px !important;
}

.MuiAccordion-root.active.MuiAccordion-rounded {
  background: #f6e6dd !important;
  color: #003087 !important;
}

.MuiAccordion-rounded.active .MuiTypography-root {
  font-size: 15px !important;
  font-weight: bold !important;
}

.MuiAccordion-root.active .MuiAccordionSummary-expandIconWrapper svg {
  color: #e35205 !important;
}

.MuiAccordion-root.active .MuiAccordionSummary-root {
  background-color: #fff0e8 !important;
  padding: 0px 10px 0px 0px !important;
  height: 61px !important;
}

.MuiAccordion-root.active .MuiAccordionSummary-content svg {
  font-size: 1.8rem !important;
}

.MuiAccordion-root.active .MuiAccordionSummary-content .MuiButton-startIcon {
  margin-right: 9px;
  background-color: #e35205 !important;
  color: #ffffff !important;
  border-radius: 50%;
  padding: 9px;
  border: 2px solid #e35205;
}

.MuiAccordion-root.active .MuiAccordionSummary-content a:hover {
  background-color: transparent;
}

.MuiAccordion-root.active .SubMenuButton:hover {
  color: #fff !important;
}

.MuiAccordion-root.active .MuiAccordionSummary-content a {
  padding: 0px 0px 0px 10px !important;
}

.MuiAccordion-root.active .MuiAccordionDetails-root {
  padding: 0px !important;
}

.h-partitionKey,
.h-sortKey {
  display: none;
}

/* ================  Common Style VV end ================= */

/* ================  Checkout CSS Start ================= */
.menuItems {
  max-height: 270px;
  overflow-y: scroll;
  background-color: #f8fbff;
}

.clientSearch {
  margin-bottom: 30px;
}

.clientSearch .css-dxnb72-MuiFormControl-root {
  width: 379px;
}

.clientItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clientItem .MuiBox-root {
  background: #ffffff;
  width: 377px;
  margin-left: 40px;
  border: 2px solid #e7eff9;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #003087;
  padding: 22px 30px;
}

/* .clientItem .css-cpknzq-MuiButtonBase-root-MuiButton-root */
.clientItem .remove-client-button {
  padding: 0;
  background: transparent;
  min-width: auto;
  margin-left: 15px;
}

.clientItem .remove-client-button:hover {
  /* opacity: 0.7; */
  background-color: transparent !important;
}

.MuiStepper-root {
  max-width: 590px;
  margin: 0 auto;
}

.MuiTabs-flexContainer {
  border-bottom: 1px solid #d9d9d9;
  background: #f8fbff;
}

.dynamicTabs .MuiTabs-flexContainer .MuiTab-root {
  font-family: 'Myriad Pro', sans-serif !important;

  width: 19% !important;

  font-weight: 600 !important;

  font-size: 16px !important;

  line-height: 20px;

  text-align: center;

  letter-spacing: 0.1px;

  height: 48px;

  color: #000000 !important;

  background-color: transparent !important;
}

.dynamicTabs .MuiTabs-root,
.dynamicTabs .MuiTabs-flexContainer,
.dynamicTabs .MuiTabs-indicator {
  height: 48px;
  width: 100%;
}

.dynamicTabs .MuiTabs-indicator {
  box-shadow: none;
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #003087;
}

/*custome stepper*/
.stepper-change .MuiStepLabel-iconContainer {
  position: relative;
  width: 35px;
  height: 35px;
}

.stepper-change .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  color: transparent !important;
  border: 2px solid #003087 !important;
  border-radius: 20px !important;
  width: 35px !important;
  height: 35px !important;
}

.stepper-change .Mui-disabled .MuiSvgIcon-root {
  border-color: #000000 !important;
  border-width: 1px !important;
}

.stepper-change .MuiStepConnector-line {
  border-color: #91b0e8f2 !important;
}

.stepper-change .Mui-active .MuiStepConnector-line,
.stepper-change .Mui-completed .MuiStepConnector-line {
  border-color: #003087 !important;
}

.stepper-change .MuiStepConnector-horizontal {
  top: 17px !important;
  left: calc(-50% + 17px) !important;
  right: calc(50% + 17px) !important;
}

.stepper-change .MuiStepLabel-iconContainer::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #003087;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.stepper-change .Mui-disabled::before,
.stepper-change .Mui-completed::before {
  content: unset !important;
}

.stepper-change .Mui-completed .MuiSvgIcon-root {
  fill: #003087 !important;
  border: unset !important;
}

.stepper-change .Mui-active,
.stepper-change .Mui-completed {
  /* color: var(--text-primary) !important; */
}

/*end*/
.dynamicTabs .MuiTabs-flexContainer .MuiTab-root.Mui-selected {
  font-family: 'Myriad Pro', sans-serif !important;

  font-size: 16px !important;

  font-weight: 600 !important;

  color: #003087 !important;

  background: transparent !important;
}

div[role='tabpanel']>.MuiBox-root {
  padding: 0;
}

/* .cartTable>div{padding: 0 !important;} */
.cartTable:not(.no-width) .MuiDataGrid-cell:nth-of-type(1),
.cartTable:not(.no-width) .MuiDataGrid-columnHeader:nth-of-type(1) {
  width: 12% !important;
  min-width: 12% !important;
}

.cartTable:not(.no-width) .MuiDataGrid-cell,
.cartTable:not(.no-width) .MuiDataGrid-columnHeader {
  width: 18% !important;
  min-width: 18% !important;
}

.cartTable .MuiPaper-root {
  border: 1px solid #d9d9d9;
  border-radius: 19px 19px 0px 0px;
}

.cartTable .css-v8c0h1-MuiDataGrid-root .MuiDataGrid-cellContent {
  overflow: visible;
}

/* .filterBtn,
.searchBar .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(5, 50, 136, 0.5) !important;
  border-radius: 10px;
} */

.searchBar .MuiInputLabel-shrink {
  background: #ffff !important;
  height: 25px;
  padding: 0 10px;
  line-height: 30px;
  top: 3px !important;
}

.orderSuccess {
  padding: 20vh 30px;
}

.checkoutModal .css-1pu3y48-MuiPaper-root-MuiDialog-paper {
  max-width: 575px;
  width: 575px;
  padding: 0 35px;
}

.checkoutModal .MuiDialogContent-root {
  padding: 20px 15px 35px;
}

.checkoutModal .dialogTitle {
  padding: 20px 15px 0;
  line-height: 1;
}

.checkoutModal .MuiButtonBase-root {
  padding: 14px 0 0px;
}

.checkoutModal.transactionModal .dialogTitle {
  padding-top: 45px;
  font-weight: 600;
  font-size: 40px;
  color: #003087;
  line-height: 1;
}

.checkoutModal.transactionModal .dialogTitle .MuiTypography-root {
  font-weight: 600;
  font-size: 40px;
  color: #003087;
}

.transactionModal .MuiDialogContent-root {
  padding: 0;
}

.transactionModal .MuiDialogContent-root p .MuiTypography-root {
  font-weight: 400;
  font-size: 20px;
  color: #003087;
  margin-bottom: 0 !important;
}

.transactionModal .MuiDialogContent-root p {
  line-height: 1;
  margin-bottom: 30px;
}

.custom-table .MuiDataGrid-row .MuiDataGrid-cell:last-child {
  display: none !important;
  width: 0px !important;
}

.css-v8c0h1-MuiDataGrid-root .MuiDataGrid-row,
.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  display: flex;
  width: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.custom-table .MuiDataGrid-row .MuiDataGrid-cell {
  border-bottom: 0px;
}

.custom-table .MuiDataGrid-row .MuiDataGrid-cell .MuiDataGrid-cellContent {
  text-wrap: wrap;
}

.custom-table .MuiDataGrid-row {
  border-bottom: 1px solid;
  border-color: rgba(224, 224, 224, 1);
}

.termsFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 25px;
  margin-bottom: 70px;
}

.termsFlex>.MuiBox-root {
  width: 50%;
}

.termsFlex .MuiTypography-body1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;

  color: #003087;
  margin-bottom: 15px;
}

.termsBox {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  max-width: 371px;
  padding: 17px 35px 17px 17px;
  border: 1px solid #eaeaea;
  max-height: 136px;
  overflow-y: auto;
  border-radius: 5px;
}

.termsFlex .right {
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-self: stretch;
}

.termsFlex .right>.MuiBox-root:nth-of-type(1) {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.termsFlex .right .MuiCheckbox-root {
  padding: 0;
}

.termsFlex .right>.MuiBox-root:nth-of-type(1) .MuiTypography-body1 {
  margin-left: 15px;
  margin-bottom: 0;
  color: #000000;
}

.termsFlex .right>.MuiBox-root:nth-of-type(2) {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-left: 40px;
}

.topStripe {
  position: absolute;
  top: -60px;
  width: 100%;
  z-index: 11;
}

@media (max-width: 1120px) {
  .paymentHead {
    max-width: 40%;
  }
}

@media (max-width: 991px) {
  .paymentHead {
    max-width: 47%;
  }

  .termsFlex>.MuiBox-root {
    width: 47%;
  }
}

/* ---css Ayushi--- */
.gift-card-buttons {
  justify-content: right !important;
}

/* ================  Checkout CSS END ================= */

/* ================  Bundles CSS Start ================= */
.page-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
  color: #003087;
}

.table-container-style .label-style-text-field {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #053288 !important;
  letter-spacing: -0.05em !important;
  top: 5px !important;
  margin-right: 30px !important;
  background-color: white !important;
}

.bundle-p-tag {
  text-align: center;
  margin-left: 17px;
  color: black;
  font-weight: 400;
  font-size: 16px;
}

.draw-grey-line .grey-line {
  border: none;
  border-top: 1px solid #ccc !important;
  width: 318px;
  text-align: center;
  margin-left: 35px;
  margin-top: 40px;
}

.grey-line-forCreateBundle {
  border: none;
  border-top: 1px solid #ccc !important;
  width: 318px;
  text-align: center;
  margin-left: 14px;
  height: 9.6px;
  margin-top: 21px;
}

.submit-button {
  padding: 17px 20px 17px 20px !important;
  background-color: #003087 !important;
  color: white !important;
  font-size: 16px !important;
}

.sell-online-txt {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.bundle-toggle {
  position: relative;
  left: 20px;
}

.table-container-style .MuiInputBase-formControl {
  height: 70px;
}

.table-container-style .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid rgba(0, 48, 135, 0.7) !important;
  border-radius: 12px;
}

.user-details-container .details-row .details-col .details-value {
  font-weight: 700;
  font-size: 16px !important;
  line-height: 40px;
  /* line-height: 17px; */
  letter-spacing: 0.0001rem !important;
  color: #a5a5a5;
  margin-top: -2px !important;
}

.bundleDesc {
  font-weight: 700;
  font-size: 16px !important;
  line-height: normal;
  /* line-height: 17px; */
  letter-spacing: 0.0001rem !important;
  color: #a5a5a5;
  margin-top: 9px !important;
}

.user-details-container .details-row .details-col .details-label {
  float: left;
  font-weight: 700;
  line-height: 40px;
  /* letter-spacing:0cap;  */
  color: #525252;
  margin-right: 20px;
  text-transform: uppercase;
  /* line-height: 17px; */
}

.header-bundle-item {
  font-weight: 600;
  font-size: 25px;
  letter-spacing: -2.2%;
  align-items: center;
  line-height: 50px;
  margin-left: 30px;
  color: #003087;
}

/* ================  Bundles CSS END ================= */
/* ================  Bundles CSS END ==================== */

/* ================  Promotions CSS END ================= */
.group-icon {
  margin-right: 10px;
  border: 1px solid;
  padding: 2px;
}

.add-promotion-card {
  background-color: #f8fbff !important;
  padding: 0px 10px 20px 10px !important;
  border: 1px solid #c5c5c5 !important;
}

/* ================  Promotions CSS END ================= */
.membership-details-colorSelector {
  display: flex;
  align-items: center;
}

.membership-details-colorCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 14px;
}

.membership-details-activeColor {
  border: 3px solid black;
}

/* ================  Bundles CSS END ================= */

.border-orange {
  border: 1px solid #e35205;
  padding: 2px 20px;
  border-radius: 25px;
}

.p-5 {
  padding: 5px !important;
}

.text-light-black {
  color: #525252 !important;
}

.uppercase-text,
.promocode-text input {
  text-transform: uppercase !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

/*=======================Contract management Start ======================================*/
.contrHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.contractMain {
  padding: 5px 15px 5px 38px;
}

.headHeading {
  font-size: 40px;
  color: #003087;
  line-height: 40px;
  width: 100%;
}

.contrHeader a {
  text-decoration: none;
}

.newPricingBtnSec div button .newPricingBtn {
  color: #00266c !important;
}

a:hover,
a:focus {
  opacity: 0.8;
}

.themeColor {
  color: #003087;
  margin-top: 10px;
  margin-bottom: 14px;
}

.themeColor.createTermtext {
  text-align: start;
}

.termsCheckBoxSec {
  margin: 20px 0 40px 0px;
}

.sideBarBottomBtnSec {
  position: absolute;
  bottom: 20px;
  width: 90%;
}

.btnOne {
  height: 50px;
  background: #003087;
  text-align: center;
  color: #fff;
  width: 200px;
  border-radius: 10px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
  display: block;
}

.btnOne:hover {
  color: #fff;
  text-decoration: none;
}

.contStatusRow {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  margin-bottom: 30px;
}

.statusBox {
  background: #f8fbff;
  border-radius: 10px;
  width: 33%;
  padding: 15px;
  display: flex;
  column-gap: 10px;
  margin-bottom: 20px;
}

.BoxImgSec {
  width: 45px;
  height: 45px;
}

.BoxImgSec img {
  width: 45px;
  height: 45px;
}

.statusBoxMid h3 {
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
}

.statusBoxMid {
  flex: 1;
}

.statusBoxMid p {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin-bottom: 1px;
}

.dropDownSec {
  width: 30px;
}

.dropDownSec .dropdown-toggle::after {
  content: '';
  display: none;
}

.termsCheckBoxSec label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.css-56vmk3-MuiFormControlLabel-root {
  align-items: flex-start;
  column-gap: 10px;
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.termsCheckBoxSec span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.css-19uoicb-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0;
}

.signatureMain {
  display: flex;
  column-gap: 15px;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

canvas.mySignatureBox {
  width: 283px;
  height: 104px;
  background: #fff;
  box-shadow: none;
  border: 2px solid #00308780;
  border-radius: 10px;
  padding: 5px 10px;
}

.clearLink {
  color: #003087;
  text-decoration: underline;
  font-weight: 600;
}

.createTermtext {
  margin-bottom: 41px;
}

.textAreaMain textarea.css-1vc1sp3 {
  width: 100%;
  background: #fff;
  border: 2px solid #00308780;
  height: 206px;
  max-height: 210px;
  border-radius: 12px;
  resize: none;
  box-shadow: none;
  color: #003087;
  padding: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.textAreaMain textarea.css-1vc1sp3::placeholder {
  color: #003087;
  font-weight: 600;
}

.tabsSec .MuiTabs-root.css-s3gjr6-MuiTabs-root {
  height: 100%;
}

.tabsSec .MuiTabs-flexContainer.css-1pyy021-MuiTabs-flexContainer {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 14px;
}

.tabsSec .MuiTabs-flexContainer button {
  width: 32.6% !important;
  height: 76px;
  border-radius: 12px;
  display: flex;
  border: 2px solid #003087;
  padding: 28px 25px;
  font-size: 14px;
  font-weight: bolder;
  text-align: start;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 20px;
  /* min-width: 355px; */
}

.lastImg {
  position: absolute;
  right: 20px;
}

.firstImg {
  margin-right: 4px;
  filter: brightness(0) saturate(100%) invert(16%) sepia(31%) saturate(5676%) hue-rotate(208deg) brightness(93%) contrast(100%);
}

.tabSec .MuiTabs-flexContainer button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary:hover {
  /* color: rgba(255, 255, 255, 0.7); */
  color: #003087 !important;
}

.MuiTabs-flexContainer .Mui-selected {
  background: #003087 !important;
  color: #fff !important;
}

.MuiTabs-flexContainer .Mui-selected .firstImg {
  filter: contrast(0.5);
  filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(7432%) hue-rotate(269deg) brightness(113%) contrast(94%);
}

.MuiTabs-flexContainer .Mui-selected .lastImg {
  filter: contrast(0.5);
  filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(7432%) hue-rotate(269deg) brightness(113%) contrast(94%);
}

.tabsSec span.MuiTabs-indicator.css-1ol5aw2-MuiTabs-indicator {
  display: none;
}

/* .tabsSec button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary.Mui-selected.css-18fz0y7-MuiButtonBase-root-MuiTab-root */
.filterSecMain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 21.65px;
}

.searchSec {
  width: 296px;
  position: relative;
}

.searchSec span.fa.fa-search {
  position: absolute;
  right: 20px;
  top: 12px;
  color: #003087;
}

.searchSec input.form-control {
  padding-left: 20px;
  background: linear-gradient(0deg, #f8fbff, #f8fbff),
    linear-gradient(0deg, rgba(0, 48, 135, 0.5), rgba(0, 48, 135, 0.5));
  border: 2px solid #00308780;
  border-radius: 12px;
  height: 40.7px;
}

.searchSec input.form-control::placeholder {
  color: #003087;
  font-size: 12px;
}

.allContrTableSec {
  background: #f8fbff;
  border-radius: 12px;
  padding: 20px;
}

.allContrTableSec .MuiDataGrid-root.MuiDataGrid-withBorderColor.css-v8c0h1-MuiDataGrid-root {
  border: none;
}

.dataTableMain .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor {
  width: 100% !important;
  max-width: 100% !important;
  width: 200px !important;
  text-align: center;
}

.allAppointTable .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor {
  width: 100% !important;
  max-width: 100% !important;
  width: 300px !important;
  text-align: center;
}

.allAppointTable .MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorderColor {
  width: 300px !important;
  min-width: auto !important;
  max-width: 100% !important;
  /*border: none;*/
}

.dataTableMain .css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
  color: #000;
}

.dataTableMain .MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorderColor {
  width: 200px;
  min-width: auto !important;
  max-width: 100% !important;
  /*border: none;*/
}

.dataTableMain .MuiDataGrid-columnHeaders.css-1iyq7zh-MuiDataGrid-columnHeaders {
  border: none;
  height: 50px;
}

.dividerClass {
  margin-top: 15px;
  margin-bottom: 15px;
}

.dataTableMain .MuiDataGrid-cellContent {
  color: #5c5c5c;
}

.dataTableMain .css-v8c0h1-MuiDataGrid-root .MuiDataGrid-cell {
  /*border: none;*/
  border-bottom-color: lightgray;
}

.dataTableMain .css-v8c0h1-MuiDataGrid-root .MuiDataGrid-row:hover,
.dataTableMain .css-v8c0h1-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background-color: inherit !important;
}

.dataTableMain .MuiDataGrid-columnHeader:focus,
.dataTableMain .MuiDataGrid-cell:focus {
  outline: none !important;
  /*border: none;*/
}

.dataTableMain .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor.css-wop1k0-MuiDataGrid-footerContainer {
  border: none;
}

.dataTableMain .MuiToolbar-root.css-qgjodz-MuiToolbar-root-MuiTablePagination-toolbar {
  display: flex;
  align-items: baseline;
}

.dataTableMain p#mui-6 {
  color: #000;
  font-weight: 600;
}

.dataTableMain .css-1ileel7-MuiInputBase-root-MuiTablePagination-select {
  /*color: #000;*/
  font-weight: bold;
}

.dataTableMain p.MuiTablePagination-displayedRows.css-13dgrt5-MuiTablePagination-displayedRows {
  /*color: #000;*/
  font-weight: 600;
}

.dataTableMain .css-1a0t9c1-MuiSvgIcon-root-MuiSelect-icon {
  top: calc(50% - 11px);
}

.headerTop {
  background: #7891c036;
  height: 47px;
  width: 100%;
  padding: 10px;
}

.dataTableMain .content-layout-wrapper.content-layout-half {
  margin-left: 95px !important;
}

.headerTopCloseIcon {
  float: right;
}

.headerTopCloseIcon i {
  background: #fff;
  color: #000;
  border-radius: 50%;
  border: none;
  height: 27px;
  width: 27px;
  text-align: center;
  line-height: 28px;
}

.saveBtn {
  width: 70px;
}

.saveBtn:hover {
  color: #fff;
  opacity: 0.7;
}

.inputFieldRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
  column-gap: 20px;
}

.inputFieldRow form.MuiBox-root.css-1w3x5cp {
  width: 50%;
  height: 70px;
}

.inputFieldRow .MuiInputBase-root.MuiOutlinedInput-root.css-109o592-MuiInputBase-root-MuiOutlinedInput-root {
  height: 70px;
}

.inputFieldRow label#outlined-basic-label {
  top: -5px;
  font-weight: 600;
  font-size: 16px;
  display: block;
  padding: 0;
  margin: 0 !important;
  line-height: 48px;
}

.inputFieldRow input#outlined-basic {
  font-size: 16px;
  color: #000;
  margin: 0;
}

.contDropDownSec {
  width: 50%;
}

.contDropDownSec .makeStyles-autocomplete-1 {
  width: 100%;
}

.contDropDownSec .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.css-1i6e319-MuiInputBase-root-MuiOutlinedInput-root {
  height: 70px;
}

.contDropDownSec .sort-dropdown.css-jozeqt-MuiStack-root {
  margin: 0;
}

.contDropDownSec label#combo-box-demo-label {
  top: -5px;
  font-weight: 600;
  font-size: 16px !important;
  display: block;
  padding: 0;
  margin: 0 !important;
  line-height: 45px;
  background: #fff;
  width: 135px;
}

.contDropDownSec input#combo-box-demo {
  color: #000;
  font-size: 16px;
}

.contDropDownSec .css-1aikvp0-MuiAutocomplete-listbox {
  list-style: none;
  margin: 0;
  padding: 8px 5px;
  max-height: 40vh;
  overflow: auto;
  background: #fff;
  color: #000;
}

.contDropDownSec .css-1aikvp0-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background: none;
  color: #fff;
  background: #003087 !important;
  border-radius: 6px;
  /* font-size: 20px; */
}

.contDropDownSec .css-1aikvp0-MuiAutocomplete-listbox .MuiAutocomplete-option {
  border-bottom: 1px solid #ccc;
  padding: 12px 10px;
}

.contDropDownSec .css-1aikvp0-MuiAutocomplete-listbox .MuiAutocomplete-option:last-of-type {
  border: none !important;
}

.contDropDownSec .css-1aikvp0-MuiAutocomplete-listbox {
  padding: 5px 20px;
  border: 2px solid #003087;
  /* border-top:none; */
}

.newContTabSec ul#pills-tab li {
  height: 70px;
  margin-bottom: 20px;
  width: 32%;
  text-align: start;
}

.newContTabSec ul#pills-tab li.nav-item a.active {
  border-bottom: none;
  background: #003087;
  color: #fff;
  border-radius: 12px;
}

.newContTabSec ul#pills-tab {
  border: none;
  background: none;
  justify-content: space-between;
  height: auto;
}

.newContTabSec ul#pills-tab li.nav-item a {
  height: 100%;
  line-height: 55px;
  border: 2px solid #003087;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 28px 25px;
}

.newContTabSec ul#pills-tab li.nav-item a img {
  /* filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%); */
  filter: invert(0.18) sepia(1) saturate(10.4) hue-rotate(223deg) brightness(0.64);
}

.newContTabSec ul#pills-tab li.nav-item a.active>img {
  /* filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%); */
  filter: invert(3.05) sepia(0.5) saturate(5.2) hue-rotate(187.6deg) brightness(1.2);
}

.newContTabSec ul#pills-tab li.nav-item a.active>.changeColor {
  filter: none;
}

.newContTabSec ul#pills-tab li.nav-item a>.changeColor {
  filter: invert(0.18) sepia(1) saturate(10.4) hue-rotate(223deg) brightness(0.3);
}

.newContTabSec .newContTabSec ul#pills-tab li.nav-item a>.tickIcon {
  background: #003087;
  border-radius: 50%;
  color: #fff;
  text-align: center;
}

.newContTabSec .newContTabSec ul#pills-tab li.nav-item a.active>.tickIcon {
  background: #fff;
}

.tickIcon {
  margin-left: auto;
  background: #003087;
  color: #fff;
  border-radius: 50%;
  height: 21px;
  width: 21px;
  text-align: center;
  line-height: 21px;
}

.selectAppDropDownSec .contDropDownSec {
  width: 70%;
}

.selectAppDropDownSec .css-14ugh9h-MuiAutocomplete-root {
  width: 60%;
}

.selectAppDropDownSec {
  margin-bottom: 21px;
}

.selectAppDropDownSec .contDropDownSec label#combo-box-demo-label {
  width: 200px;
}

/* switch  */
.switchSec label.MuiFormControlLabel-root.css-56vmk3-MuiFormControlLabel-root {
  display: flex;
  flex-direction: row-reverse;
  width: 75%;
  column-gap: 10px;
}

.switchBox span.MuiTypography-root.css-4ggt7d-MuiTypography-root {
  color: #000;
  font-size: 16px;
  font-weight: 600 !important;
}

.css-djaggy-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: #00308780;

  opacity: 1;
  border: 0;
}

.switchBox {
  width: 15%;
}

.daySDropdownSec {
  width: 20%;
  margin-left: 22px;
}

.daySDropdownSec.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.sc-gEvEer.hWlKqd.css-o3m6jb-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 100% !important;
}

.daySDropdownSec.MuiFormControl-root.MuiFormControl-fullWidth.css-q8hpuo-MuiFormControl-root {
  height: 100%;
}

.switchSec {
  display: flex;
  align-items: center;
  margin-bottom: 31px;
}

/* switch end  */
.allContrTableSec.allAppointTable {
  background: #fff;
}

.rightSideBarMain .MuiPaper-root.MuiPaper-elevation.css-kjdr2u-MuiPaper-root-MuiDrawer-paper {
  height: 100%;
  background-color: #fff;
  border-radius: 35px 0 0 35px;
  padding: 0px;
  /* max-width: 400px; */
  /* min-width: 400px; */
}

.stepperMain {
  background: #fff;
  /* width:400px; */
  max-width: 400px;
  padding: 30px 25px;
  height: 100%;
  min-width: 400px;
}

.stepperMain .MuiBox-root.css-8atqhb {
  background: #fff;
  height: 100%;
}

.stepperMain .MuiStepper-root.MuiStepper-horizontal.css-1dpsq7h-MuiStepper-root {
  background: #fff;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.stepperMain span.MuiStepLabel-root.MuiStepLabel-horizontal.css-ascpo7-MuiStepLabel-root {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0;
  width: 200px;
}

.stepperMain span.MuiStepLabel-label.css-1do7u57-MuiStepLabel-label {
  font-size: 15px;
  color: #000;
  font-weight: 600;
}

.stepperMain span.MuiStepLabel-label.Mui-active.css-1do7u57-MuiStepLabel-label {
  color: #003087;
}

.stepperMain button.css-1733zhs-MuiButtonBase-root-MuiStepButton-root {
  padding: 0;
}

.stepperMain {
  position: relative;
}

.stepperMain .MuiStep-root.MuiStep-horizontal.css-1bw0nnu-MuiStep-root {
  padding: 0;
}

.stepperMain .css-j5w0w9-MuiStepConnector-root {
  width: 46%;
  position: absolute;
  top: 25%;
  left: 21%;
}

.stepperMain .MuiBox-root.css-1re7lhi {
  display: none;
}

.stepOneMain {
  height: 430px;
  /* background:#ccc; */
}

.stepInputSecOne {
  margin-bottom: 10px;
}

.stepInputSecOne .css-109o592-MuiInputBase-root-MuiOutlinedInput-root {
  height: 70px;
}

.stepInputSecOne label#outlined-basic-label {
  line-height: 45px;
  font-weight: bold;
  top: -5px;
  font-size: 16px;
}

.switchNInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px !important;
}

.switchNInput .switchBox {
  width: 34%;
}

.switchNInput .switchBox label {
  column-gap: 20px;
  flex-direction: row-reverse;
}

.numSessInputSec {
  margin-bottom: 15px;
}

.numSessInputSec label#outlined-basic-label {
  background: #fff;
}

.sellSwitchSec {
  margin-bottom: 10px;
}

.sellSwitchSec .switchBox {
  width: 100%;
}

.sellSwitchSec label {
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0;
}

.radioButtonSec {
  margin-bottom: 30px;
}

.radioButtonSec .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root {
  width: 100%;
}

.radioButtonSec label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 0;
  color: #000;
  font-size: 16px;
}

.stPricingSwSec .switchBox {
  width: 100%;
}

.stPricingSwSec label {
  margin: 0;
  width: 100%;
  column-gap: 20px;
}

button.moreItemTab {
  justify-content: center !important;
  align-items: center;
}

.tabsSec .moreItemSec {
  width: 32.6%;
  height: 76px;
  border-radius: 12px;
  display: flex;
  border: 2px solid #003087;
  padding: 28px 25px;
  color: #003087;
  font-size: 14px;
  font-weight: bolder;
  text-align: start;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 20px;
  /*max-width: 363px;*/
}

.moreItemSec button {
  width: 100% !important;
  max-width: 100%;
  display: flex !important;
  justify-content: center !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  height: auto;
  position: relative;
}

.moreItemSec button:hover {
  opacity: 0.8;
  background: none;
}

.simpleDropDownMain {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.simpleDropDownMain .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1m9ybye-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  width: 358px !important;
  right: 100px !important;
  left: unset !important;
  /* top: px !important; */
}

/* contract management updated code  */
/* dynamic tab two css  */
.myContainerRight {
  max-width: 100%;
  overflow: hidden;
}

.dynamicTabsTwoMain .MuiTabs-flexContainer.css-1pyy021-MuiTabs-flexContainer {
  background: #f8fbff !important;
  height: 48px !important;
}

.dynamicTabsTwoMain .MuiTabs-flexContainer .Mui-selected {
  background: transparent !important;
  border-bottom: 3px solid #003087 !important;
  border-radius: 0;
  color: #003087 !important;
}

.dynamicTabsTwoMain .MuiTabs-flexContainer .MuiTab-root {
  width: 33% !important;
  height: auto;
  background: none !important;
  line-height: 40px;
  font-weight: 600 !important;
  font-size: 16px;
}

.dynamicTabsTwoMain .css-1ol5aw2-MuiTabs-indicator,
span.MuiTabs-indicator {
  background: none !important;
  border: none;
  box-shadow: none;
}

.dynamicTabsTwoMain {
  margin: 26px 0px;
}

.dynamicTabsTwoMain .MuiTabs-scroller.MuiTabs-fixed.css-kqhgxw-MuiTabs-scroller {
  height: 50px;
}

button.pauseEditBtn {
  background: transparent;
  text-align: center;
}

.pauseEditBtn button {
  border: none;
  padding: 6px 10px;
  background: transparent;
  border: 1px solid #c5c5c5;
  color: #525252;
  border-radius: 5px;
  cursor: pointer;
}

.pausePlayBtn {
  border: none;
  padding: 6px 10px;
  background: transparent;
  color: #525252;
  border-radius: 5px;
  cursor: pointer;
}

.css-v8c0h1-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-v8c0h1-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.pauseContSideBar {
  padding: 40px 20px 60px;
  min-height: 87vh;
  position: relative;
}

.pauseSbHead {
  text-align: start;
  margin-bottom: 30px;
}

.pauseSbHead h2 {
  margin-bottom: 20px;
  color: #003087;
}

.pauseSbHead h4 {
  margin-bottom: 20px;
  color: #000;
  font-weight: 600;
}

.terminationModalSec {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.pauseContSideBar .css-1i6e319-MuiInputBase-root-MuiOutlinedInput-root {
  height: 70px;
}

.pauseContSideBar .MuiInputBase-root.MuiOutlinedInput-root.css-1i6e319-MuiInputBase-root-MuiOutlinedInput-root svg {
  fill: #003087;
}

.modalPauseBtn {
  width: 100% !important;
}

.terminationModalSec button {
  width: 100%;
}

.pauseModalMain .MuiBox-root.css-1irwc8r {
  border: none !important;
  width: 500px;
  border-radius: 12px;
  box-shadow: 0 0 4px #fff;
  min-height: 300px;
  padding: 60px 100px 30px;
}

.modalCloseSec {
  position: absolute;
  right: 20px;
  top: 20px;
}

.modalCloseSec button {
  color: red;
  background: none;
  border: none;
  cursor: pointer;
}

.modalCloseSec button:focus {
  opacity: 0.7;
}

.pauseModalMain h2#modal-modal-title {
  color: #003087 !important;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.pauseModContSec h4 {
  color: #000 !important;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.pauseModalBtnSec {
  display: flex;
  column-gap: 10px;
  justify-content: center;
}

.pauseModCancelBtn,
.pauseModProcBtn {
  width: 46%;
}

.pauseModCancelBtn button {
  width: 100%;
  background-color: transparent !important;
  color: #003087 !important;
  border: 2px solid #003087 !important;
  font-weight: 700 !important;
}

.pauseModProcBtn button {
  width: 100%;
}

.nDynModCdSec {
  height: auto !important;
  min-height: auto !important;
  padding: 0;
  margin-bottom: 20px;
}

.managBottomBtnSec {
  display: flex;
  column-gap: 15px;
}

.managBottomBtnSec a:nth-of-type(2) {
  background: none;
  color: #003087;
}

.managBottomBtnSec a {
  text-decoration: none;
}

.sessionNoSec {
  text-align: start;
}

/*=======================Contarct managment END==========================================*/

.cross-imageicon {
  position: absolute;
  top: 30px;
  right: 38px;
  font-size: 30px;
  cursor: pointer;
}

.MuiFormHelperText-root.Mui-error.Mui-disabled {
  color: #d13a00 !important;
}

/* =============POS DASHBOARD==============*/
@font-face {
  font-family: 'open-sans.regular';
  src: url('/public/open-sans.regular.ttf') format('truetype');
}

.image-alignment-style {
  margin-top: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.pos-card {
  height: 350px !important;
}

.pos-card-sub {
  /* height: 240px !important; */
  overflow: auto;
  aspect-ratio: 5/1;
}

.pos-text-styling {
  font-family: 'Myriad Pro' !important;
}

.pos-color-green {
  color: #32b2ac !important;
}

.pos-color-black {
  color: #525252 !important;
}

.main-card-container .MuiCardHeader-root .MuiCardHeader-title {
  font-size: 18px !important;
  color: #003087 !important;
  font-family: 'Myriad Pro' !important;
  font-weight: 600 !important;
}

.pos-title {
  font-family: 'open-sans.regular' !important;
  font-weight: 600 !important;
  color: #ffffff !important;
}

.icon-call {
  color: #003087 !important;
  cursor: pointer !important;
}

.button-style-pos {
  height: 46px !important;
}

.pos-text-size-74 {
  font-family: 'Myriad Pro', sans-serif !important;

  font-size: 74.67px !important;

  font-weight: 600 !important;

  color: #003087 !important;
}

/* ========= POS DASHBOARD CSS ENDS =========*/
/* PROMOTION MANAGEMENT CSS START*/

.promotion-management .card-block {
  border: 0.4px solid #c5c5c5;
  background-color: #f8fbff;
  padding-top: 0px !important;
}

.postfix-icon .MuiTypography-root.MuiTypography-body1 {
  font-weight: 700;
  color: #003087 !important;
}

.toggle-btn-block label {
  margin-left: 0px !important;
}

.toggle-btn-block.right label {
  margin-right: 0px !important;
}

.dynamicTabs.promotion .MuiTabs-flexContainer .MuiTab-root {
  width: 20% !important;
}

/* .cartTable.promotion-cart-table .MuiDataGrid-cell:nth-of-type(1),
.cartTable.promotion-cart-table .MuiDataGrid-columnHeader:nth-of-type(1) {
  width: 12% !important;
  min-width: 12% !important;
}
*/
.cartTable.promotion-cart-table .MuiDataGrid-cell,
.cartTable.promotion-cart-table .MuiDataGrid-columnHeader {
  width: 25% !important;
  justify-content: space-between;
}

.cartTable.promotion-cart-table .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: space-between;
}

.invisible {
  visibility: hidden !important;
}

/* PROMOTION MANAGEMENT CSS END*/

.font-italic {
  font-style: italic;
}

/* =============INVENTORY MANAGMENT DASHBOARD==============*/

.pos-inventory-card {
  height: 166px !important;
}

.pos-inventory-text-size-74 {
  color: #003087 !important;
  font-family: Myriad Pro !important;
  font-size: 40px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  /* 51px */
  letter-spacing: -0.374px !important;
  text-transform: uppercase !important;
}

/* ========= INVENTORY MANAGMENT DASHBOARD CSS ENDS =========*/

/* =============STAFF AVAILABILITY ==============*/
.e-schedule-toolbar-container {
  display: none;
}

/* ========= STAFF AVAILABILITY  CSS ENDS =========*/

.appointmentMain .switchSec>div {
  width: 100%;
}

.w-100vh {
  width: 100vh !important;
}

.compain-text {
  font-weight: 600 !important;
  color: #003087 !important;
  font-size: 16px !important;
}

.table-header-campaign {
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.text-create-template {
  font-weight: 800 !important;
  font-size: 20px !important;
  color: rgba(0, 0, 0, 1);
}

.cohort-btn-outline {
  border: 2px solid #003087 !important;
  height: 50px !important;
  font-weight: 600 !important;
}

.cohorts-detail-header {
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 1);
  font-size: 16px !important;
}

.select-btn-cohorts .css-o416uh-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  height: 40px !important;
}

.cohorts-outlined-btn {
  border: 2px solid #003087 !important;
}

.cohort-input-styling {
  font-size: 16px !important;
  background-color: white !important;
  height: 70px !important;
}

.top-margin-cohorts {
  margin-top: 20px !important;
  height: 70px !important;
}

.top-margin-cohorts-date {
  margin-top: 42px !important;
}

.template-detail-value {
  font-weight: 700 !important;
  font-size: 16px !important;
  /* line-height: 17px; */
  color: rgba(0, 0, 0, 0.5) !important;
}

.template-edit-btn {
  padding: 10px !important;
}

.text-message-template .css-1kwfuff-MuiInputBase-input-MuiOutlinedInput-input {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.5);
}

.campaign-details.css-oi5h2z-MuiGrid-root {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.5);
}

.cohort-detail-text {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.error-text_template {
  color: #d13a00;
}

.staff-dashboard-role-btn .role-button {
  background: #f8fbff;
  border: 2px solid rgba(0, 48, 135, 0.5) !important;
  border-radius: 12px !important;
  color: #525252 !important;
  min-width: 99px !important;
  margin-left: 30px;
  margin-top: 20px;
}

.staff-dashboard-title {
  font-family: 'open-sans.regular' !important;
  font-weight: 600 !important;
  font-size: 12px;
  letter-spacing: 3%;
  line-height: 16.34px;
  color: rgba(82, 82, 82, 1) !important;
}

.staff-title-card {
  background-color: #f8fbff;
  border: 1px solid rgba(197, 197, 197, 1);
  margin-top: 15px;
  width: 97%;
  margin-left: 30px;
}

.staff-dash-img {
  margin-left: 20px !important;
}

.staff-dash-main .css-rtuwu6-MuiTypography-root {
  font-size: 20px !important;
  color: #003087 !important;
  font-family: 'Myriad Pro' !important;
  font-weight: 800 !important;
  line-height: 19.2px !important;
  letter-spacing: 13.5 !important;
}

/* .staff-sub-cardss .MuiCardHeader-root .MuiCardHeader-title {
  background-color: '#F8FBFF' !important;
  border: '0.4px solid #C5C5C5' !important;
   margin-top: '10px' !important;
    width: '450px' !important;
    height: '85px' !important;
     display: 'flex' !important;
     justify-content: 'center' !important;
     align-items: 'center' !important;
} */
.client-name-staff {
  font-weight: 700 !important;
  color: rgba(82, 82, 82, 1) !important;
  font-family: 'Myriad Pro' !important;
  font-size: 18px !important;
  line-height: 19.2px !important;
  margin-bottom: 10px !important;
}

.client-description-staff {
  font-weight: 400 !important;
  color: rgba(82, 82, 82, 1) !important;
  font-family: 'Myriad Pro' !important;
  font-size: 16px !important;
  line-height: 16.8px !important;
}

.staff-dash-date-time {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 14.4px !important;
  font-family: 'Myriad Pro' !important;
  color: rgba(0, 0, 0, 1);
}

.staff-dash-role {
  color: rgba(0, 48, 135, 1);
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: 'Myriad Pro' !important;
}

.day-date-staff {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 1) !important;
  font-size: 16px !important;
}

.client-name-staff-dash {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 1) !important;
}

.client-body-staff {
  font-weight: 600 !important;
  color: rgba(82, 82, 82, 1);
  font-size: 14 !important;
}

.classname-staff-dash {
  font-weight: 600 !important;
  color: rgba(0, 48, 135, 1) !important;
  font-size: 16px !important;
}

.w-100vh {
  width: 100vh !important;
}

.space_2em,
.custom-form .input-field-dropdown-styling.space_2em {
  letter-spacing: -0.01em !important;
}

.location-table-container .table-header .text-transform-none {
  text-transform: none !important;
}

.dynamicTabs.clienttab .MuiTabs-flexContainer .MuiTab-root {
  width: 16.67% !important;
}

.input-field-styling.no-height [class*='MuiInputBase-formControl'] {
  height: auto !important;
  background-color: unset !important;
  width: 150px;
}

.input-field-styling.no-height [class*='MuiInputBase-formControl'] input {
  background: unset !important;
}

.date-noboder .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

.-d-none {
  display: none !important;
}

.-d-block {
  display: block !important;
}

.liability-section-container {
  border: 2px solid#003088 !important;
  border-radius: 10px;
  width: 100%;
  min-height: 170px;
  padding: 5px;
  font-size: 15px !important;
  /* color: rgba(0, 0, 0, 0.54) !important; */
  font-family: 'Myriad Pro' !important;
}

.liability-section-container .ql-size-small.ql-font-serif {
  font-family: 'Myriad Pro' !important;
  font-size: 15px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.red-status.css-1r8yoc8-MuiTableCell-root {
  color: red !important;
}

.green-status.css-1r8yoc8-MuiTableCell-root {
  color: green !important;
}

.black-status.css-1r8yoc8-MuiTableCell-root {
  color: black !important;
}

.paylater-red.css-1r8yoc8-MuiTableCell-root {
  color: red !important;
}

.remove-file {
  position: absolute;
  top: 7px;
  right: 5px;
  font-size: 17px;
  cursor: pointer;
}

.single-uploaded-files {
  width: auto;
  border: 1px solid #d9d9d9;
  float: left;
  margin: 0 10px 10px 0px;
  position: relative;
  padding: 10px 18px;
  display: flex;
  justify-content: center;
  border-radius: 25px;
}

.text-grey {
  color: rgba(0, 0, 0, 0.54) !important;
}

.custom-form .MuiFormControl-root .height-auto,
.height-auto,
.custom-form .height-auto .MuiFormControl-root .MuiOutlinedInput-root {
  height: auto !important;
  min-height: auto !important;
}

/* CSS FOR CHECKOUT PROCESS STEP BAR STARTS */

.stepper-change-check-out>.MuiStepConnector-root {
  top: 14px !important;
  left: calc(-50% + 15px) !important;
  right: calc(50% + 15px) !important;
}

.stepper-change-check-out>.MuiStepConnector-root.Mui-disabled>.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #d9d9d9 !important;
}

.step-labeling-check-out span.MuiStepLabel-label {
  text-align: center !important;
  margin-top: 5px !important;
}

.step-labeling-check-out .MuiStepLabel-label.Mui-completed {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;
  color: #003087 !important;
  letter-spacing: -0.022em;
}

/* Css class for step bar text styling when on active page*/
.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;
  color: #003087 !important;
}

/* Css class for step bar text styling when on remaining page*/

.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label,
.step-labeling-check-out span.Mui-disabled {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #000000 !important;
  line-height: 16.8px;
}

/* Css class for step bar text styling when page is completed*/

.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-completed,
.step-labeling-check-out,
.step-labeling-check-out span.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: -0.022em;
  color: #003087 !important;
  line-height: 16.8px;
}

/* Css class for step bar when it is not active */
.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root {
  color: transparent !important;
}

.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root.Mui-active {
  color: #003087 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  height: 0.5rem !important;
  width: 0.39em !important;
}

/* Css class for display text of step no as none*/

.MuiStepIcon-text {
  display: none !important;
}

/* Css for step bar when step is completed*/

.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.Mui-completed {
  color: #003087 !important;
  /* margin: auto !important; */
  margin: -3px !important;
  vertical-align: middle !important;
  height: auto !important;
  border-radius: 100% !important;
  width: 1.5em !important;
  /*border: solid #003087 !important ;
  border-spacing:0px !important;*/
}

.step-labeling-check-out>.MuiStepLabel-iconContainer {
  border-radius: 50% !important;
  border: 2px solid #003087 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;
}

.step-labeling-check-out.Mui-disabled>.MuiStepLabel-iconContainer {
  border-radius: 50% !important;
  border: 2px solid #000000 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;
}

/* Css styling for horizontal line */

.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #003087 !important;
}

/* CSS FOR CHECKOUT PROCESS STEP BAR ENDS */

.header-black-14 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #000000 !important;
}

.header-black-12 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #000000 !important;
}

.cartTable .MuiDataGrid-columnSeparator {
  visibility: hidden !important;
}

/* CSS CLASS FOR TOGGLE BUTTON CUSTOMIZATION STARTS*/
.MuiSwitch-switchBase+.MuiSwitch-track {
  border: 1.8px solid #003087 !important;
  background-color: white !important;
}

.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  border: none !important;
  background-color: rgba(0, 48, 135, 0.3) !important;
}

.MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb {
  background-color: #003087 !important;
}

/*CSS CLASS FOR TOGGLE BUTTON CUSTOMIZATION ENDS*/

/* CSS FOR CHECKOUT PROCESS STEP BAR STARTS */

.stepper-change-check-out>.MuiStepConnector-root {
  top: 14px !important;
  left: calc(-50% + 15px) !important;
  right: calc(50% + 15px) !important;
}

.stepper-change-check-out>.MuiStepConnector-root.Mui-disabled>.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #d9d9d9 !important;
}

.step-labeling-check-out span.MuiStepLabel-label {
  text-align: center !important;
  margin-top: 5px !important;
}

.step-labeling-check-out .MuiStepLabel-label.Mui-completed {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;
  color: #003087 !important;
  letter-spacing: -0.022em;
}

/* Css class for step bar text styling when on active page*/
.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;
  color: #003087 !important;
}

/* Css class for step bar text styling when on remaining page*/

.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label,
.step-labeling-check-out span.Mui-disabled {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #000000 !important;
  line-height: 16.8px;
}

/* Css class for step bar text styling when page is completed*/

.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-completed,
.step-labeling-check-out,
.step-labeling-check-out span.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: -0.022em;
  color: #003087 !important;
  line-height: 16.8px;
}

/* Css class for step bar when it is not active */
.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root {
  color: transparent !important;
}

.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root.Mui-active {
  color: #003087 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  height: 0.5rem !important;
  width: 0.39em !important;
}

/* Css class for display text of step no as none*/

.MuiStepIcon-text {
  display: none !important;
}

/* Css for step bar when step is completed*/

.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.Mui-completed {
  color: #003087 !important;
  /* margin: auto !important; */
  margin: -3px !important;
  vertical-align: middle !important;
  height: auto !important;
  border-radius: 100% !important;
  width: 1.5em !important;
  /*border: solid #003087 !important ;
  border-spacing:0px !important;*/
}

.step-labeling-check-out>.MuiStepLabel-iconContainer {
  border-radius: 50% !important;
  border: 2px solid #003087 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;
}

.step-labeling-check-out.Mui-disabled>.MuiStepLabel-iconContainer {
  border-radius: 50% !important;
  border: 2px solid #000000 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;
}

/* Css styling for horizontal line */

.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #003087 !important;
}

/* CSS FOR CHECKOUT PROCESS STEP BAR ENDS */

.header-black-14 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #000000 !important;
}

.header-black-12 {
  font-family: 'Myriad Pro', sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #000000 !important;
}

.cartTable .MuiDataGrid-columnSeparator {
  visibility: hidden !important;
}

/* CSS FOR CHECKOUT PROCESS STEP BAR STARTS */

.stepper-change-check-out>.MuiStepConnector-root {
  top: 14px !important;
  left: calc(-50% + 15px) !important;
  right: calc(50% + 15px) !important;
}

.stepper-change-check-out>.MuiStepConnector-root.Mui-disabled>.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #d9d9d9 !important;
}

.step-labeling-check-out span.MuiStepLabel-label {
  text-align: center !important;
  margin-top: 5px !important;
}

.step-labeling-check-out .MuiStepLabel-label.Mui-completed {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;
  color: #003087 !important;
  letter-spacing: -0.022em;
}

/* Css class for step bar text styling when on active page*/
.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;
  color: #003087 !important;
}

/* Css class for step bar text styling when on remaining page*/

.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label,
.step-labeling-check-out span.Mui-disabled {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #000000 !important;
  line-height: 16.8px;
}

/* Css class for step bar text styling when page is completed*/

.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-completed,
.step-labeling-check-out,
.step-labeling-check-out span.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: -0.022em;
  color: #003087 !important;
  line-height: 16.8px;
}

/* Css class for step bar when it is not active */
.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root {
  color: transparent !important;
}

.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root.Mui-active {
  color: #003087 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  height: 0.5rem !important;
  width: 0.39em !important;
}

/* Css class for display text of step no as none*/

.MuiStepIcon-text {
  display: none !important;
}

/* Css for step bar when step is completed*/

.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.Mui-completed {
  color: #003087 !important;
  /* margin: auto !important; */
  margin: -3px !important;
  vertical-align: middle !important;
  height: auto !important;
  border-radius: 100% !important;
  width: 1.5em !important;
  /*border: solid #003087 !important ;
  border-spacing:0px !important;*/
}

.step-labeling-check-out>.MuiStepLabel-iconContainer {
  border-radius: 50% !important;
  border: 2px solid #003087 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;
}

.step-labeling-check-out.Mui-disabled>.MuiStepLabel-iconContainer {
  border-radius: 50% !important;
  border: 2px solid #000000 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;
}

/* Css styling for horizontal line */

.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #003087 !important;
}

/* CSS FOR CHECKOUT PROCESS STEP BAR ENDS */

/* CSS FOR CHECKOUT PROCESS STEP BAR STARTS */

.stepper-change-check-out>.MuiStepConnector-root {
  top: 14px !important;
  left: calc(-50% + 15px) !important;
  right: calc(50% + 15px) !important;
}

.stepper-change-check-out>.MuiStepConnector-root.Mui-disabled>.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #d9d9d9 !important;
}

.step-labeling-check-out span.MuiStepLabel-label {
  text-align: center !important;
  margin-top: 5px !important;
}

.step-labeling-check-out .MuiStepLabel-label.Mui-completed {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;
  color: #003087 !important;
  letter-spacing: -0.022em;
}

/* Css class for step bar text styling when on active page*/
.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;
  color: #003087 !important;
}

/* Css class for step bar text styling when on remaining page*/

.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label,
.step-labeling-check-out span.Mui-disabled {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #000000 !important;
  line-height: 16.8px;
}

/* Css class for step bar text styling when page is completed*/

.step-labeling-check-out>.MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel>.MuiStepLabel-label.Mui-completed,
.step-labeling-check-out,
.step-labeling-check-out span.Mui-active {
  font-family: 'Myriad Pro', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: -0.022em;
  color: #003087 !important;
  line-height: 16.8px;
}

/* Css class for step bar when it is not active */
.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root {
  color: transparent !important;
}

.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.MuiStepIcon-root.Mui-active {
  color: #003087 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  height: 0.5rem !important;
  width: 0.39em !important;
}

/* Css class for display text of step no as none*/

.MuiStepIcon-text {
  display: none !important;
}

/* Css for step bar when step is completed*/

.step-labeling-check-out>.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel>.Mui-completed {
  color: #003087 !important;
  /* margin: auto !important; */
  margin: -3px !important;
  vertical-align: middle !important;
  height: auto !important;
  border-radius: 100% !important;
  width: 1.5em !important;
  /*border: solid #003087 !important ;
  border-spacing:0px !important;*/
}

.step-labeling-check-out>.MuiStepLabel-iconContainer {
  border-radius: 50% !important;
  border: 2px solid #003087 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;
}

.step-labeling-check-out.Mui-disabled>.MuiStepLabel-iconContainer {
  border-radius: 50% !important;
  border: 2px solid #000000 !important;
  height: 2.1em !important;
  width: 2.1em !important;
  border-collapse: collapse !important;
}

/* Css styling for horizontal line */

.MuiStepConnector-line {
  border-top-width: 2px !important;
  border-color: #003087 !important;
}

/* CSS FOR CHECKOUT PROCESS STEP BAR ENDS */

.select-location.select-loc-55 .fYIQCa {
  height: 55px !important;
}

.scanLabel {
  background-color: #f8fbff !important;
}

.orange-text-dialog {
  font-weight: 400 !important;

  font-size: 17px !important;

  line-height: 14.4px !important;

  font-family: 'Myriad Pro' !important;

  color: #e35205 !important;
}

.blue-text-dialog {
  font-weight: 400 !important;

  font-size: 17px !important;

  line-height: 14.4px !important;

  font-family: 'Myriad Pro' !important;

  color: #003087 !important;
}

.background-blue {
  background-color: #f8fbff !important;
}

.table-border-styling {
  border: 1px solid rgba(0, 0, 0, 0.13) !important;

  border-top-left-radius: 25px !important;

  border-top-right-radius: 25px !important;
}

.custom-table .MuiDataGrid-virtualScrollerRenderZone,
.custom-table .MuiDataGrid-row {
  width: 100% !important;
}

.custom-table .MuiDataGrid-columnHeadersInner,
.custom-table .MuiDataGrid-columnHeadersInner div[role='row'] {
  width: 100% !important;

  display: flex;

  justify-content: space-between;
}

.red-status.css-1r8yoc8-MuiTableCell-root {
  color: red !important;
}

.green-status.css-1r8yoc8-MuiTableCell-root {
  color: green !important;
}

.black-status.css-1r8yoc8-MuiTableCell-root {
  color: black !important;
}

.paylater-red.css-1r8yoc8-MuiTableCell-root {
  color: red !important;
}

@media (max-width: 800px) {
  .membership-status-container .input-value[data-shrink='false'] {
    font-size: 11px;
  }

  .fYIQCa .MuiSelect-icon {
    font-size: 18px;

    top: 10px;
  }
}

.main-card-container.pos-card-container {
  min-height: 262px;
  height: auto;
  position: relative;
}

.main-card-container.pos-card-container .pos-card-sub.pos-card-content {
  aspect-ratio: 2/1;
}

.main-card-container.pos-card-container .pos-card-action {
  right: 0;
  bottom: 0;
}

.Custom-Table.table-container.table-header-capitalization table thead th {
  text-transform: capitalize !important;
}

.padding-left-27 {
  padding-left: 28px !important;
}

.task-view {
  max-height: 600px !important;
}

.e-schedule .e-vertical-view .e-header-cells .e-header-day {
  width: 21px;

  height: 19px;

  opacity: 0.77px;

  display: table;

  color: #000000b2;

  padding-bottom: 0;

  font-family: Myriad Pro;

  font-size: 12px;

  font-weight: 700;

  line-height: 19px;

  letter-spacing: 0px;

  text-align: left;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

/*CSS vikash*/
.e-schedule .e-vertical-view .e-header-cells .e-header-day {
  width: 21px;
  height: 19px;
  opacity: 0.77px;
  display: table;
  color: #000000B2;
  padding-bottom: 0;
  font-family: Myriad Pro;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.e-schedule .e-vertical-view .e-header-cells .e-header-date {
  display: none;
}

.input-field-dropdown-to-from>div>label {
  top: 2px !important;
}

@media (max-width: 1300px) {
  .ipad-screen-resolution-text {
    font-size: 16px !important;
    font-Weight: 600 !important;
    letter-Spacing: 0.01em !important;
    text-Align: left !important;
    padding-Right: 16px !important;
  }

  .ipad-screen-resolution-dropdown {
    border-Radius: "12px" !important;
    height: 45px !important;
    max-Width: auto !important;
    min-Width: auto !important;
    font-Style: normal !important;
    font-Weight: 600 !important;
    font-Size: 11px !important;
    line-Height: 30px !important;
  }
}

@media (max-width: 1450px) {
  .ipad-screen-resolution-text-day-date {
    font-size: 16px !important;
    font-Weight: 600 !important;
    line-Height: 25px !important;
  }

  .ipad-screen-resolution-icon {
    font-size: 10px !important;
  }

  .month-card .date {
    font-family: 'Myriad Pro Bold', Arial, sans-serif;
    height: 55px !important;
    font-size: 12px !important;
    max-width: 75px;
    width: 40px !important;
    background: var(--text-primary);
    text-align: center;
    color: #fff;
    padding: 4px;
    border-radius: 5px 0px 0px 5px;
    background-color: #003087;
    /* color: #FFFFFF; */
  }
}

/*CSS vikash*/

.custom-field.home_searchbox .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #e35205 !important;
  border-radius: 12px;
}

.MuiAutocomplete-hasPopupIcon.css-omjnfk-MuiAutocomplete-root .MuiOutlinedInput-root {
  height: 36px;
}

.custom-field.home_searchbox .MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  padding: 0px !important;
}

.home_mt-4 {
  margin-top: -3px;
}

.liability-section-container {
  height: 300px;
  overflow: auto;
  padding: 10px;
  border-radius: 5px;
}

.custom-datepicker.promo-enddate button.Mui-disabled {
  pointer-events: unset !important;
}

.header-class.MuiPaper-elevation4 {
  box-shadow: none !important;
}